import React from "react";

import { withNamespaces, Interpolate } from "react-i18next";
import top_banner_bg from "../../../../assets/images/employer-banner.jpg";

import corpBranding from "../../../../assets/images/corp_branding.jpg";
import internationalTalents from "../../../../assets/images/international_talents.jpg";
import plan_post from "../../../../assets/images/i010-plan_01.svg";
import plan_hire from "../../../../assets/images/i011-plan_02.svg";
import plan_hire_plus from "../../../../assets/images/i012-plan_03.svg";
import AiPic from "../../../../assets/images/new-employer-landing/AI.png";
import HhPic from "../../../../assets/images/new-employer-landing/headhunter.png";
import RecruitPic from "../../../../assets/images/new-employer-landing/recruitment.png";
import SocialPic from "../../../../assets/images/new-employer-landing/socialmedia.png";
import { Helmet } from "react-helmet";
import InternalUri from "../../../models/internal_uri";
import ThreeBasicRules from "../../general/ThreeBasicRules";
import PartnerLogos from "../PartnerLogos";
import advantage_1 from "../../../../assets/images/i051_plan-feature-1.svg";
import advantage_2 from "../../../../assets/images/i052_plan-feature-2.svg";
import advantage_3 from "../../../../assets/images/i053_plan-feature-3.svg";
import icon_arrow_up_b from "../../../../assets/images/icon/icon_28_arrow_up_B100.svg";
import { Element, Link } from "react-scroll/modules";
import { CSSTransitionGroup } from "react-transition-group";
import { UseReferralUrlWrapper } from "../../../containers/general";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import WorldMap from "../map/WorldMap.jsx";
import { count } from "draft-js/lib/DefaultDraftBlockRenderMap";
import CountryChart from "../d3/country/CountryChart";
import JobFunctionChart from "../d3/job_function/JobFunctionChart";
import ExperienceChart from "../d3/experience/ExperienceChart";

// react simple map

const RADIAN = Math.PI / 180;

class EmployerLandingPage extends React.Component {
	constructor(props) {
		super(props);
		this.handleScroll = this.handleScroll.bind(this);
		this.goCreateEmployer = this.goCreateEmployer.bind(this);
		this.goDashboard = this.goDashboard.bind(this);
		this.goLogIn = this.goLogIn.bind(this);
		this.state = {
			show_back_to_plan: false,
		};

		this.slider_settings = {
			dots: false,
			arrows: false,
			infinite: false,
			slidesToShow: 2,
			initialSlide: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "300px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 991,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "185px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 720,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "160px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 600,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "105px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "36px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll() {
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		const element = document.querySelector("section.section-our-plans");
		if (element) {
			const benchmark = element.offsetTop + element.offsetHeight;
			if (scrollTop > benchmark) {
				if (!this.state.show_back_to_plan) {
					this.setState({ show_back_to_plan: true });
				}
			} else {
				if (this.state.show_back_to_plan) {
					this.setState({ show_back_to_plan: false });
				}
			}
		}
	}

	goCreateEmployer = (e, selected_plan = null) => {
		e.preventDefault();
		const internal_uri = new InternalUri();
		this.props.history.push(
			`${internal_uri.formatted_frontend_create_employer_page_path()}${
				selected_plan ? `?selected_plan=${selected_plan}&preselected` : ""
			}`,
		);
	};

	goDashboard = (e) => {
		e.preventDefault();
		const internal_uri = new InternalUri();
		this.props.history.push(internal_uri.formatted_dashboard_index_page_path());
	};

	goLogIn = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("log-in");
	};

	render() {
		const { reduxTokenAuth, current_user } = this.props;
		const { t } = this.props;
		const { goCreateEmployer, goDashboard, goLogIn } = this;
		const { show_back_to_plan } = this.state;
		const internal_uri = new InternalUri();
		return (
			<div
				className="page-employer-landing content-wrapper"
				style={{ backgroundColor: "#fff" }}>
				<UseReferralUrlWrapper />
				<Helmet>
					<title>{t("general:employer")}｜Meet.jobs</title>
				</Helmet>
				<section
					className="section-top-banner"
					style={{
						background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7) ),url(${top_banner_bg})`,
						backgroundSize: "cover",
						backgroundPosition: "center center",
					}}>
					<div className="container">
						<h1
							dangerouslySetInnerHTML={{
								__html: t(
									"high_quality_and_efficient_recruitment_service_with_competitive_price",
								),
							}}
						/>
						<p
							style={{ maxWidth: "70%" }}
							dangerouslySetInnerHTML={{
								__html: t(
									"meet_jobs_is_a_headhunting_platform_leveraging_on_social_referral_and_community_sharing_and_focusing_on_international_talents_and_job_opportunities_",
								),
							}}></p>
					</div>
				</section>
				<section className="section-our-partners">
					<div className="container">
						<h2 className="title">{t("partners")}</h2>
						<PartnerLogos eventCategory="705_logos_employer_landing" />
					</div>
				</section>
				<section
					className="how-we-get-people container"
					style={{ margin: "3rem auto" }}>
					<h2 className="title">{t("how_meetjob_acquire_talents")}</h2>
					<div
						className="how-we-get-people-grid"
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gap: "1rem",
						}}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.5rem",
								width: "100%",
							}}>
							<img
								src={AiPic}
								alt=""
								style={{
									objectFit: "contain",
									// backgroundColor: "blue",
									width: "60%",
									aspectRatio: " 1",
								}}
							/>
							<div style={{ textAlign: "center" }}>
								<h4 style={{ marginBottom: "0.5rem" }}>{t("ai_title")}</h4>
								<p style={{ width: "80%", margin: "1.5rem auto" }}>
									{t("ai_content")}
								</p>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.5rem",
								width: "100%",
							}}>
							<img
								src={HhPic}
								alt=""
								style={{
									objectFit: "contain",
									// backgroundColor: "blue",
									width: "60%",
									aspectRatio: " 1",
								}}
							/>
							<div style={{ textAlign: "center" }}>
								<h4 style={{ marginBottom: "0.5rem" }}>
									{t("headhunter_title")}
								</h4>
								<p style={{ width: "80%", margin: "1.5rem auto" }}>
									{t("headhunter_content")}
								</p>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.5rem",
								width: "100%",
							}}>
							<img
								src={SocialPic}
								alt=""
								style={{
									objectFit: "contain",
									// backgroundColor: "blue",
									width: "60%",
									aspectRatio: " 1",
								}}
							/>
							<div style={{ textAlign: "center" }}>
								<h4 style={{ marginBottom: "0.5rem" }}>{t("social_title")}</h4>

								<p style={{ width: "80%", margin: "1.5rem auto" }}>
									{t("social_content")}
								</p>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.5rem",
								width: "100%",
							}}>
							<img
								src={RecruitPic}
								alt=""
								style={{
									// backgroundColor: "blue",
									objectFit: "contain",
									width: "60%",
									aspectRatio: " 1",
								}}
							/>
							<div style={{ textAlign: "center" }}>
								<h4 style={{ marginBottom: "0.5rem" }}>{t("events_title")}</h4>
								<p style={{ width: "80%", margin: "1.5rem auto" }}>
									{t("events_content")}
								</p>
							</div>
						</div>
					</div>
				</section>
				<section
					className="world-map"
					style={{
						margin: "3rem auto",
						textAlign: "center",
						backgroundColor: "white",
					}}>
					<h2 style={{ margin: "5rem auto" }}>{t("across_71_countries")}</h2>

					<div
						style={{
							width: "90%",
							// aspectRatio: "16/9",
							margin: "0 auto",
							objectFit: "contain",
							// transform: "translate(-1%,1%) ",
							position: "relative",
						}}>
						<WorldMap t={t} />
					</div>
				</section>
				<h2
					style={{
						padding: "5%",
						textAlign: "center",
						backgroundColor: "#f5f6f7",
					}}>
					{t("chart_main_title")}
				</h2>
				<section
					className="data-set"
					style={{
						padding: "10%",
						paddingTop: "5%",
						textAlign: "center",
						transform: "translateY(-7%)",
						backgroundColor: "#f5f6f7",
					}}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "1.5rem",
							flexWrap: "wrap",
						}}>
						<div
							style={{
								padding: "10px",
								width: "100%",
								// aspectRatio: "1",
								height: "fit-content",
								marginBottom: "5rem",
								// gap: "1rem",
							}}>
							<JobFunctionChart t={t} />
						</div>
						<div
							style={{
								padding: "10px",
								width: "100%",
								// aspectRatio: "1",
								height: "fit-content",
								// gap: "1rem",
							}}>
							<ExperienceChart t={t} />
							{/* <p
								style={{
									fontSize: "1rem",
									fontWeight: "bold",
									marginBottom: 0,
									color: "#184bba",
								}}>
								{t("chart_experience_title")}
							</p> */}
						</div>

						{/* <div
							style={{
								backgroundColor: "white",
								maxWidth: "410px",
								padding: "10px",
								width: "100%",
								aspectRatio: "1",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								alignItems: "center",
								borderRadius: "1rem",
								// gap: "1rem",
							}}>
							<CountryChart t={t} />
							<p
								style={{
									fontSize: "1rem",
									fontWeight: "bold",
									marginBottom: 0,
									color: "#184bba",
								}}>
								{t("chart_culture_title")}
							</p>
						</div> */}
					</div>
				</section>
				<section
					className="section-our-plans"
					id="our_plans"
					style={{ padding: "5%" }}>
					<div className="container">
						<h2 className="title">{t("our_plans")}</h2>
					</div>
					<div className="plans-wrapper">
						<div className="container">
							<div
								className="plans"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexWrap: "wrap",
								}}>
								{/*<div className="plan-wrapper">*/}
								{/*    <div className="block plan plan-post">*/}
								{/*        <h4>{t('general:plan_post')}</h4>*/}
								{/*        <div*/}
								{/*            className='image-wrapper'*/}
								{/*            style={{backgroundImage: `url(${plan_post})`}}*/}
								{/*        />*/}
								{/*        <div className="prices">*/}
								{/*            <h3>{t('general:us__30')}</h3>*/}
								{/*            <h5>{t('general:each_job_post_per_month')}</h5>*/}
								{/*        </div>*/}
								{/*        <hr />*/}
								{/*        <p>{t('general:applicant_tracking')}</p>*/}
								{/*        <p>{t('general:seo_friendly')}</p>*/}
								{/*        <p>{t('general:direct_message')}</p>*/}

								{/*        {*/}
								{/*            (reduxTokenAuth.currentUser.isSignedIn) ?*/}
								{/*                (*/}
								{/*                    (!current_user.is_employer_user) ?*/}
								{/*                        <a*/}
								{/*                            className="btn btn-larger btn-flat btn-fill btn-call-to-action"*/}
								{/*                            onClick={ e => goDashboard(e) }*/}
								{/*                        >*/}
								{/*                            <h5>{t('general:select_plan')}</h5>*/}
								{/*                        </a> :*/}
								{/*                        <a*/}
								{/*                            className="btn btn-larger btn-flat btn-fill btn-call-to-action"*/}
								{/*                            onClick={ e => goCreateEmployer(e, 'post_only') }*/}
								{/*                        >*/}
								{/*                            <h5>{t('general:select_plan')}</h5>*/}
								{/*                        </a>*/}
								{/*                ) :*/}
								{/*                <a*/}
								{/*                    className="btn btn-larger btn-flat btn-fill btn-call-to-action"*/}
								{/*                    onClick={ e => goLogIn(e) }*/}
								{/*                >*/}
								{/*                    <h5>{t('general:select_plan')}</h5>*/}
								{/*                </a>*/}
								{/*        }*/}
								{/*    </div>*/}
								{/*</div>*/}
								<div
									className="plan-wrapper"
									style={{ flex: "1 1 0%", minWidth: "350px" }}>
									<div className="block plan plan-post">
										<h4>{t("general:plan_hire")}</h4>
										<div
											className="image-wrapper"
											style={{ backgroundImage: `url(${plan_hire})` }}
										/>
										<div className="prices">
											<h3>{t("general:_16__annual_salary")}</h3>
											<h5>{t("general:per_successful_hire")}</h5>
										</div>
										<hr />
										{/*<h6>{t('general:everything_in_post__plus_')}</h6>*/}
										<p>{t("general:commnuity_referral_quick_and_precise")}</p>
										<p>
											{t("general:emplouyer_brandind_and_dynamic_promotion")}
										</p>
										<p>
											{t(
												"general:integrated_marketing_and_speed_interview_event",
											)}
										</p>

										{reduxTokenAuth.currentUser.isSignedIn ? (
											current_user.is_employer_user ? (
												<a
													className="btn btn-larger btn-flat btn-fill btn-call-to-action"
													onClick={(e) => goDashboard(e)}>
													<h5>{t("general:select_plan")}</h5>
												</a>
											) : (
												<a
													className="btn btn-larger btn-flat btn-fill btn-call-to-action"
													onClick={(e) =>
														goCreateEmployer(e, "social_referral")
													}>
													<h5>{t("general:select_plan")}</h5>
												</a>
											)
										) : (
											<a
												className="btn btn-larger btn-flat btn-fill btn-call-to-action"
												onClick={(e) => goLogIn(e)}>
												<h5>{t("general:select_plan")}</h5>
											</a>
										)}
									</div>
								</div>
								<div
									className="plan-wrapper"
									style={{ flex: "1 1 0%", minWidth: "350px" }}>
									<div className="block plan plan-hire">
										<h4>{t("general:plan_hire_")}</h4>
										<div
											className="image-wrapper"
											style={{ backgroundImage: `url(${plan_hire_plus})` }}>
											{/*<span className="recommend">{t('general:recommend').toUpperCase()}</span>*/}
										</div>
										<div className="prices">
											<h3>{t("general:_20__annual_salary")}</h3>
											<h5>{t("general:per_successful_hire")}</h5>
										</div>
										<hr />
										{/*<h6>{t('general:everything_in_hire_plan')}</h6>*/}
										{/*<p>{t('general:recruitment_consulting_service')}</p>*/}
										<p>{t("general:everything_in_social_plan")}</p>
										<p>{t("general:professional_hunter_search")}</p>
										<p>{t("general:double_screening_ai")}</p>
										<p>
											{t("general:professional_slill_language_adaptive_tests")}
										</p>
										<p>{t("general:customized_service_contract")}</p>

										{reduxTokenAuth.currentUser.isSignedIn ? (
											current_user.is_employer_user ? (
												<a
													className="btn btn-larger btn-flat btn-fill btn-call-to-action"
													onClick={(e) => goDashboard(e)}>
													<h5>{t("general:select_plan")}</h5>
												</a>
											) : (
												<a
													className="btn btn-larger btn-flat btn-fill btn-call-to-action"
													onClick={(e) =>
														goCreateEmployer(e, "custom_referral")
													}>
													<h5>{t("general:select_plan")}</h5>
												</a>
											)
										) : (
											<a
												className="btn btn-larger btn-flat btn-fill btn-call-to-action"
												onClick={(e) => goLogIn(e)}>
												<h5>{t("general:select_plan")}</h5>
											</a>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <section className="section-our-advantages">
					<div className="container">
						<h2 className="title">{t("our_advantages")}</h2>
						<div className="main-advantage main-advantage-1">
							<div className="row no-margin">
								<div className="col-lg-offset-2 col-lg-10 col-md-offset-1 col-md-6 col-sm-offset-1 col-sm-6 col-xs-12 no-padding">
									<h2
										dangerouslySetInnerHTML={{ __html: t("_17_countries") }}
									/>
									<h3>
										{t("talents___employers___vacancies_across_17_countries")}
									</h3>
								</div>
							</div>
							<img src={advantage_1} alt="" />
						</div>
						<div className="main-advantage main-advantage-2">
							<div className="row no-margin">
								<div className="col-lg-offset-5 col-lg-7 col-md-offset-6 col-md-6 col-sm-offset-5 col-sm-7 col-xs-12 no-padding">
									<h2 dangerouslySetInnerHTML={{ __html: t("_25_days") }} />
									<h3>{t("from_posting_job_to_offer_acceptance")}</h3>
								</div>
							</div>
							<img src={advantage_2} alt="" />
						</div>
						<div className="main-advantage main-advantage-3">
							<div className="row no-margin">
								<div className="col-lg-offset-3 col-lg-9 col-md-offset-1 col-md-6 col-sm-offset-1 col-sm-6 col-xs-12 no-padding">
									<h2
										dangerouslySetInnerHTML={{ __html: t("_16__service_fee") }}
									/>
									<h3>{t("compare_to_20___charged_by_headhunters")}</h3>
								</div>
							</div>
							<img src={advantage_3} alt="" />
						</div>
						<div className="plus-advantages">
							<div className="row">
								<div className="col-md-12">
									<h3 className="plus">{t("general:plus").toUpperCase()}</h3>
								</div>
								<div className="col-md-6">
									<div className="advantage">
										<h2>{t("precise_matching_by_social_referral")}</h2>
										<h3>{t("_hire_plan_")}</h3>
									</div>
								</div>
								<div className="col-md-6">
									<div className="advantage">
										<h2>{t("machine_learning_and_ai_technology")}</h2>
										<h3>{t("_hire_plan_")}</h3>
									</div>
								</div>
								<div className="col-md-6">
									<div className="advantage">
										<h2>{t("employer_branding_and_integrated_marketing")}</h2>
										<h3>{t("_hire__hire_plus_plan_")}</h3>
									</div>
								</div>
								<div className="col-md-6">
									<div className="advantage">
										<h2>{t("recruitment_consulting_service")}</h2>
										<h3>{t("_hire_plus_plan_")}</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				<section className="section-three-basic-rules">
					<div className="container">
						<h2 className="title">{t("use_first__contract_later_")}</h2>
						<p className="note">
							{t("you_need_only_to_agree_with_3_Basic_Rules__")}
						</p>
						<ThreeBasicRules forDisplay={true} />
						<p className="note">
							{t(
								"note__contract_must_be_signed_before_your_first_candidate_s_onboarding",
							)}
						</p>
						{reduxTokenAuth.currentUser.isSignedIn ? (
							current_user.is_employer_user ? (
								<a
									className="btn btn-larger btn-flat btn-fill"
									onClick={(e) => goDashboard(e)}>
									<h5>{t("start_recruiting")}</h5>
								</a>
							) : (
								<a
									className="btn btn-larger btn-flat btn-fill"
									onClick={(e) => goCreateEmployer(e)}>
									<h5>{t("start_recruiting")}</h5>
								</a>
							)
						) : (
							<a
								className="btn btn-larger btn-flat btn-fill"
								onClick={(e) => goLogIn(e)}>
								<h5>{t("start_recruiting")}</h5>
							</a>
						)}
					</div>
				</section>
				<section
					className="call-to-action"
					style={{
						padding: "5%",
					}}>
					<div className="row no-margin actions">
						<div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
							<div
								// to={internal_uri.formatted_frontend_employer_landing_page_path()}
								// data-event-category="106_section_referral"
								className="disabled-link"
								style={{}}>
								<div
									className="action-block box-shadow rounded-l"
									style={{
										backgroundImage: `url(${internationalTalents})`,
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										aspectRatio: "16/9",
										backgroundPosition: "center",
										backgroundSize: "cover",
										boxShadow: "1px 1px 5px 0px #0008",
										filter: "grayscale(100%) contrast(0.8)",
										margin: "0.5rem",
									}}>
									<span className="btn btn-larger btn-flat btn-hollow">
										<h5>{t("talents_dispatch")}</h5>
									</span>
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-5">
							<div
								// to={internal_uri.formatted_frontend_referrer_landing_page_path()}
								// data-event-category="106_section_referral"
								className="disabled-link">
								<div
									className="action-block box-shadow rounded-l"
									style={{
										backgroundImage: `url(${corpBranding})`,
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										aspectRatio: "16/9",
										backgroundPosition: "center",
										backgroundSize: "cover",
										boxShadow: "1px 1px 5px 0px #0008",
										filter: "grayscale(100%) contrast(0.8)",
										margin: "0.5rem",
									}}>
									<span className="btn btn-larger btn-flat btn-hollow">
										<h5>{t("corparation_branding")}</h5>
									</span>
								</div>
							</div>
						</div>
					</div>
				</section>
				<CSSTransitionGroup
					component="div"
					transitionName="fade-in-out"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
					{show_back_to_plan ? (
						<Link
							className="back-to-plan"
							to="our_plans"
							spy={true}
							smooth={true}
							offset={-140}
							duration={400}>
							<img src={icon_arrow_up_b} alt="" />
							<p>{t("back_to_plan")}</p>
						</Link>
					) : (
						""
					)}
				</CSSTransitionGroup>
			</div>
		);
	}
}

export default withNamespaces(["frontend_employer_landing_page", "general"])(
	EmployerLandingPage,
);
