export default {
    view_employer_page: "瀏覽企業專頁",
    basic_information: "基本資訊",
    employer_log: "企業商標",
    _recommend_size__300x300px_: "(建議尺寸: 300x300像素)",
    industry: "企業職能",
    employer_size: "企業規模",
    address: "地址",
    city: "城市/地區",
    full_address: "完整地址",
    set_as_main_office: "設為主要辦公室",
    delete_address: "刪除地址",
    add_address: "新增地址",
    website: "網站",
    employer_description: "企業描述",
    describe_your_organization_in_one_sentence: "請用一句話形容您的組織",
    make_candidates_understand_your_company_quickly____180_: "讓求職者能夠快速的了解你。 ({{count}}/180)",
    description: "描述",
    introduce_your_products_or_corporate_culture: "請深入介紹你們的產品或文化。",
    photos_and_videos: "相片及影片",
    photos: "相片",
    youtube_video_link: "Youtube 影片網址",
    employer_contact: "企業聯絡資訊",
    name: "姓名",
    phone_number: "電話",
    full_name: "姓名",
    these_information_will_be_seen_on: "這些資訊會顯示在",
    your_employer_profile_is_not_complete__are_you_sure_you_want_to_leave_this_page_: "您的企業資訊尚未完成，確定要離開這個頁面？",
    finish_later: "離開",
    keep_writing: "繼續填寫",
};