import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    closeAll,
    closeHeader,
    updateEntranceFormModal,
    updateSearchKeywords,
    updateCurrentUserCompanies,
    updateCurrentUserActiveCompany,
    updateCurrentUserEmployerRoles,
    updateCurrentUserIsReferralUser,
    updateCurrentUserIsEmployerUser,
    updateLogOutModal,
    updateCreateEmployerModal,
    updateConnectionOffModal,
    updateWelcomeEmployerModal,
    removeFlashMessage,
    updateAds,
    updateAdsCover,
    updateUtm,
    updateSearchBar,
    updateMobileMenu,
    updateSubMenu,
    addCurrentUserReferralCode,
} from '../../actions';

import App from "../views/App.jsx"
import Header from "../views/general/layouts/Header";
import Footer from "../views/general/layouts/Footer.jsx";
import JobCard from "../views/general/JobCard.jsx";
import CreateEmployerForm from "../views/general/CreateEmployerForm.jsx";
import Frontend from '../views/frontend/Frontend.jsx';
import Userpanel from '../views/userpanel/Userpanel.jsx';
import Dashboard from '../views/dashboard/Dashboard.jsx';
import Talents from "../views/talents/Talents.jsx";
import SaveJobButton from "../views/general/SaveJobButton.jsx";
import LogOutModal from "../views/general/modals/LogOutModal.jsx";
import ConnectionOffModal from "../views/general/modals/ConnectionOffModal.jsx";
import ExternalJobRedirectPage from "../views/general/pages/ExternalJobRedirectPage.jsx";
import FlashMessages from "../views/general/FlashMessages.jsx";
import TawkTo from "../views/general/TawkTo";
import UseReferralUrl from "../views/frontend/UseReferralUrl";

const VERIFY_TOKEN_REQUEST_SUCCEEDED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED';
const VERIFY_TOKEN_REQUEST_FAILED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED';

export const UseReferralUrlWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
    }),dispatch => ({
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        }
    })
)(UseReferralUrl));

export const AppWrapper = withRouter(connect(
    state => ({
        isAnyModalOpen: state.site_state.isAnyModalOpen,
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
        utm: state.utm,
    }),
    dispatch => ({
        closeAll() {
            dispatch(closeAll())
        },
        closeHeader() {
            dispatch(closeHeader())
        },
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        verifyTokenRequestFailed() {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_FAILED
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateLogOutModal(open) {
            dispatch(updateLogOutModal(open))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateAds(ads) {
            dispatch(updateAds(ads))
        },
        updateAdsCover(ads) {
            dispatch(updateAdsCover(ads))
        },
        updateUtm(utm) {
            dispatch(updateUtm(utm))
        },
    })
)(App));

export const JobCardWrapper = withRouter(connect(
    null,
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        }
    })
)(JobCard));

export const HeaderWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
        search_words: state.search_words,
    }),
    dispatch => ({
        updateSearchBar(search_bar) {
            dispatch(updateSearchBar(search_bar))
        },
        updateMobileMenu(mobile_menu) {
            dispatch(updateMobileMenu(mobile_menu))
        },
        updateSubMenu(sub_menu) {
            dispatch(updateSubMenu(sub_menu))
        },
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        },
        closeAll() {
            dispatch(closeAll())
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(Header));

export const FooterWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(Footer));

export const CreateEmployerFormWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        updateCreateEmployerModal(create_employer_modal) {
            dispatch(updateCreateEmployerModal(create_employer_modal))
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateWelcomeEmployerModal(open, banner_img_url, title, content, and_content, plus_content)  {
            dispatch(updateWelcomeEmployerModal(open, banner_img_url, title, content, and_content, plus_content))
        },
    })
)(CreateEmployerForm));

export const SaveJobButtonWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        // updateCurrentUserCompanies(companies) {
        //     dispatch(updateCurrentUserCompanies(companies))
        // },
        // updateCurrentUserActiveCompany(active_company) {
        //     dispatch(updateCurrentUserActiveCompany(active_company))
        // },
        // closeAll() {
        //     dispatch(closeAll())
        // }
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(SaveJobButton));

export const FrontendWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
    }),
    null
)(Frontend));

export const UserpanelWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
    }),
    null
)(Userpanel));

export const DashboardWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
        dashboard_sidebar_collapsed: state.site_state.dashboard_sidebar_collapsed
    }),
    null
)(Dashboard));

export const TalentsWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
    }),
    null
)(Talents));

export const LogOutModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateLogOutModal(log_out_modal) {
            dispatch(updateLogOutModal(log_out_modal))
        },
        closeAll() {
            dispatch(closeAll())
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(LogOutModal));

export const ConnectionOffModalWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ConnectionOffModal));

export const FlashMessagesWrapper = withRouter(connect(
    state => ({
        flash_messages: state.site_state.flash_messages,
    }),
    dispatch => ({
        removeFlashMessage(id) {
            dispatch(removeFlashMessage(id))
        },
    })
)(FlashMessages));

export const ExternalJobRedirectPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ExternalJobRedirectPage));

export const TawkToWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    null
)(TawkTo));
