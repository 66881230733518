export default {
    extend_all_posts: "Extend all posts",
    extend: "Extend",
    title: "Title",
    salary: "Salary",
    applications: "Applications",
    status: "Status",
    expire_in___days: "Expire in {{days_left}} days",
    expire_in_1_day: "Expire in 1 day",
    expired: "Expired",
    expired___days_ago: "Expired {{days_left}} days ago",
    expired_1_day_ago: "Expired 1 day ago",
    no_jobs_posted: "No jobs posted",
    you_haven_t_posted_any_jobs_yet_: "You haven't posted any jobs yet.",
    are_you_sure_to_post_this_draft_: "Are you sure to Post this draft?",
    are_you_sure_to_close_this_job_: "Are you sure to Close this job?",
    are_you_sure_to_delete_this_draft_: "Are you sure to Delete this draft?",
};