export default {
	country: "國家",
	contract_file_name: "Meetjobs_BusinessUserAgreement_zh_20230219.pdf",
	// contract_file_name: "Employer_Agreement_20190723.pdf",

	meet_jobs___global_talent__global_job: "Meet.jobs｜全球・人才・工作",
	refer_your_friends_for: "推薦工作給朋友",
	be_a_referrer: "成為推薦人",

	meet_jobs_introduction_content:
		"Meet.jobs 是一個面向全球，專為國際專業人才所打造的跨境社交求職平台。 Meet.jobs 的使命是讓每個人都能夠找到發揮自身最大價值的舞台，透過 Meet.jobs 上公開、透明的工作機會資訊，及與社群的交流與分享，開拓視野、獲得更多挑戰與機會。 「分享」、「信任」和「多元」，是 Meet.jobs 的核心價值，分別體現在「社群推薦」、「資訊透明」和「專注國際」三個特色。",
	employer_landing_description:
		"讓全世界幫你找人才！Meet.jobs 不只讓你張貼工作機會，更能快速準確地幫你找到你想要的人才！ Meet.Jobs 上的所有工作機會資訊，都清楚載明薪資範圍區間，而不是「面議」或「依公司規定」，這是 Meet.Jobs 的堅持。精準而完整的資訊不但對求職者公平，也提高雇主的徵才效率。 Meet.Jobs 希望透過公開、透明的工作機會資訊，建立「求職者」、「雇主」與「推薦人」互相信任的基礎，幫助求職者快速判斷要不要應徵一份工作，加速企業徵才的效率。",
	referrer_landing_description:
		"除了幫自己找工作，您也可以推薦朋友，透過 Meet.jobs 錄取工作。Meet.jobs 也提供「推薦感謝金」（約為年薪的 4%）給與成功推薦人選的用戶！ 凡透過 Meet.jobs 錄取工作的用戶，可獲得 100 USD 的「就職祝賀金」。藉此，我們邀請您分享應徵、職場、簽證以及當地生活的經歷，幫助更多的求職朋友。",

	all__jobs_: "{{- _in_place}}所有{{- _q}}工作機會",
	browse_all___jobs___meet_jobs_is_a_headhunter_platform_focusing_on_international_talents_and_jobs_:
		"瀏覽{{- _near_place}}所有{{- _q}}工作機會。Meet.jobs 是一個面向全球，專為國際專業人才所打造的跨境社交求職平台。",
	_q: " {{- keyword_q}} ",
	_in_place: "在 {{- keyword_place}} 的",
	_near_place: "在 {{- keyword_place}} 附近的",

	loading: "載入中",
	e_g__: "例：",
	or: "或",
	plus: "加上",
	also: "或",

	referral_reward: "推薦感謝金",
	onboard_reward: "就職祝賀金",

	featured: "精選",
	post: "張貼",
	search: "搜尋",
	edit: "編輯",
	edit_draft: "編輯草稿",
	close: "關閉",
	closed: "已關閉",
	update: "更新",
	delete: "刪除",
	reopen: "重啟",
	remove: "移除",
	leave: "離開",
	save: "儲存",
	saving: "儲存中",
	saved: "已儲存",
	cancel: "取消",
	publish: "發佈",
	save_draft: "儲存草稿",
	discard: "放棄",
	upgrade: "升級",
	download: "下載",
	upload: "上傳",
	publishing: "發佈中",
	learn_more: "了解更多",
	see_all: "全部",
	recent: "近期",
	post_a_job: "張貼工作機會",
	current_plan_: "目前方案：",
	clear: "清除",
	ok: "OK",
	yes: "是",
	choose_file: "選擇檔案",
	copy: "複製",
	copy_link: "複製連結",
	share_link: "分享連結",
	submit_for_friend: "代投履歷",
	copy_code: "複製代碼",
	copy_referral_link: "複製推薦代碼",
	referral_link: "推薦連結",
	copied: "已複製",
	apply: "應徵",
	invite_to_apply: "分享工作機會",
	invitation_to_apply: "應徵邀請",
	i_accept: "我接受",
	accept: "同意",
	_not_provided_: "（未提供）",
	__optional_: "（選擇性）",
	refer_this_job: "推薦此工作機會",
	refer_this_employer: "推薦此企業",
	view_on_map: "在地圖中查看",
	send: "發送",
	sending: "發送中",
	applied_at: "應徵於",
	updated_at: "更新於",
	applied: "應徵於",
	updated: "更新於",
	updated_on: "更新於",
	closed_on: "關閉於",
	due_on: "到期於",
	awaiting_confirmation: "等待確認",
	external_website: "外部網站",
	_messages: " 筆訊息",
	messages: "訊息",
	details: "應徵內容",
	comments: "內部留言",
	reference_letter: "推薦訊息",
	ref__letter: "推薦訊息",
	no_more_messages: "沒有更多訊息",
	no_more_comments: "沒有更多內部留言",
	no_more_reference_letter: "沒有更多推薦訊息",
	read: "讀取",
	write: "撰寫",
	claim: "領取獎金",
	add: "新增",
	add_file: "新增檔案",
	continue: "繼續",
	confirm: "確認",
	confirmed: "已確認",
	cancelled: "已取消",
	reject: "寄送感謝信",
	rejected: "未錄取",
	decline: "婉拒",
	next: "下一步",
	referred: "獲得推薦",
	you_don_t_have_the_proper_privilege_level_to_visit_this_page_:
		"你沒有適當的權限拜訪此頁面。",
	last_revised_on: "最新修訂於",
	pending: "等待中",
	expired: "已過期",
	job_function: "職能",

	// header、sidebar
	jobs: "工作機會",
	referrer: "我要推薦",
	employer: "企業徵才",
	column: "職涯專欄",
	job__company__or_keyword: "工作、企業或關鍵字",
	keyword: "關鍵字",
	city_or_country: "任何地點",
	log_in: "登入",
	sign_up: "註冊",
	sign_up_with_email: "使用 Email 註冊",
	log_in_with_facebook: "使用 Facebook 帳戶登入",
	sign_up_with_facebook: "使用 Facebook 帳戶註冊",
	account_setting: "帳戶設定",
	my_applications: "我的應徵",
	saved_jobs: "儲存的工作",
	my_referrals: "我的推薦",
	my_resume: "我的履歷",
	upload_resume: "上傳履歷",
	default_resume: "預設履歷",
	view_default_resume: "觀看預設履歷",
	upload_new_resume: "上傳預設履歷",
	upload_new_one: "上傳新的預設履歷",
	use_default_resume: "使用預設履歷",
	use_another_resume: "使用別的履歷",
	set_as_default_resume: "設為預設履歷",
	file_name: "檔案名稱",
	get_reward: "獲得推薦感謝金",
	log_out: "登出",
	home: "總覽",
	home_to_index: "首頁",
	back: "返回",
	job_list: "工作機會列表",
	applications: "應徵",
	employer_profile: "企業資訊",
	payment_and_invoices: "付款及帳單",
	invoices: "帳單",
	payment_setting: "付款設定",
	upgrade_plans: "升級方案",
	member_management: "成員管理",
	back_to_meet_jobs: "回到 Meet.jobs",
	create_employer: "新增企業",
	copyright___meet_jobs__: "Copyright © Meet.jobs {{year}}",
	taipei_number: "北市就服字 第0304號",
	your_list_is_empty_: "您的清單是空的。",
	collapse_menu: "收合選單",
	expand_menu: "展開選單",

	// footer
	about_us: "關於我們",
	brand: "品牌",
	contact_us: "聯絡我們",
	all_jobs: "全球工作",
	hello__meet_jobs__i_have_some_questions___:
		"嗨, Meet.jobs！ 我遇到了一些問題...",

	// privacy and terms
	privacy_policy: "隱私權政策",
	employer_agreement: "徵才企業條款",
	user_agreement: "使用者條款",
	cookie_policy: "Cookie 政策",
	summary_of_changes: "條款及政策更新總覽",
	privacy_and_terms: "條款及政策",

	// plans
	plan_post: "Post",
	plan_hire: "社群方案",
	plan_hire_: "獵才方案",

	social_referral_name: "社群方案",
	custom_referral_name: "獵才方案",

	us__30: "US $30",
	each_job_post_per_month: "每個工作機會 / 每月",
	applicant_tracking: "應徵追蹤管理",
	seo_friendly: "搜尋引擎優化",
	direct_message: "一對一訊息",

	commnuity_referral_quick_and_precise: "精準且快速的社群推薦",
	emplouyer_brandind_and_dynamic_promotion: "企業雇主品牌與主動推廣",
	integrated_marketing_and_speed_interview_event: "整合行銷與快速面試活動",

	everything_in_hire_plan: "Hire Plan 所包含的所有服務",
	recruitment_consulting_service: "招募顧問諮詢服務",
	everything_in_social_plan: "社群方案所包含的所有服務",
	professional_hunter_search: "專業獵才顧問推薦人才",
	double_screening_ai: "雙重篩選，包括 AI 和專業獵才評估",
	professional_slill_language_adaptive_tests:
		"專業技術 / 語言 / 適性等測驗評估",
	customized_service_contract: "其他客製化服務與合約內容（20%↑）",

	recommend: "最推薦",
	_8__annual_salary: "8% 全年薪資",
	per_successful_hire: "每個成功的招募",
	everything_in_post__plus_: "Post 方案的全部內容，以及",
	social_referral: "專業社群精準推薦",
	crowd_sourcing: "社團主動搜尋人才",
	peer_review: "同行專業領域評估",
	employer_branding: "僱主品牌形象推廣",
	integrated_marketing: "整合行銷徵才活動",
	_30_day_probation: "保證 30 天試用期",

	_16__annual_salary: "16% 全年薪資",
	_20__annual_salary: "20%⬆ 全年薪資",
	__annual_salary: "{{ rate }}% 全年薪資",
	everything_in_hire__plus_: "Hire 方案的全部內容，以及",
	_180_day_probation: "保證 180 天試用期",
	recruitment_consulting: "招募顧問諮詢服務",
	customized_contract: "客製化方案與條款",

	select_plan: "選擇方案",
	current_plan: "目前方案",
	selected: "已選擇",

	// three basic rules
	three_basic_rules: "三條基本規則",
	payment_by_successful_hire___of_annual_salary_:
		"人選就職再付費，費用為年薪 {{number}}%",
	___day_probation_guarantee: "Meet.jobs 保證錄取人選任滿 {{number}} 天試用期",
	salary_information_is_mandatory_: "張貼工作機會需有薪資區間",

	payment_by_successful_use_hire_or_hire_plus:
		"人選就職再付費 (採用 社群 或 獵才 方案費率)",

	// log out modal
	is_that_you_: "這是您本人嗎？",
	we_notice_that_you_re_already_logged_in_as_different_account_would_you_like_to_proceed_:
		"我們注意到您的瀏覽器已登入不同的帳號，請問您要繼續此帳號的操作嗎？",

	// connection off modal
	oops_: "糟糕！",
	unable_to_connect_to_meet_jobs: "無法連線到 Meet.jobs",

	// external job redirect page
	redirect_in___seconds: "將在 {{remaining_seconds}} 秒後自動轉址",
	please_apply_in_the_redirected_page__thank_you_:
		"請在轉址後的網頁投遞履歷，謝謝。",

	// create employer form
	profile: "填寫資料",
	choose_plan: "選擇方案",
	agreement: "同意合約",
	create_employer_account: "建立企業帳戶",
	set_up_profile: "輸入企業資料",
	employer_name: "企業名稱",
	your_employer_name: "你的企業名稱",
	contact_name: "聯絡人",
	full_name: "請輸入全名",
	contact_phone_number: "連絡人電話",
	your_phone_number: "你的電話",
	location: "地點",
	city: "城市",
	your_domain_on_meet_jobs: "您在 Meet.jobs 上的網域",
	_only_letters_numbers_and_hyphens_are_permitted_:
		"（請使用英文字母、數字、及橫線。）",
	_employer_name: "employer-name",
	how_did_you_hear_about_us_: "您透過什麼管道知道 Meet.jobs",
	a_person_s_name___contact: "介紹人的名字及聯絡方式",

	// contract
	employer_user_agreement: "徵才企業用戶合約",
	i_accept_meet_jobs_employer_user_agreement:
		"我同意 Meet.jobs 徵才企業用戶合約",
	i_accept_meet_jobs_3_basic_rules_and_employer_user_agreement:
		"我同意 Meet.jobs 三條基本規則，以及徵才企業用戶合約",
	use_first__contract_later__: "立即使用，合約候補 →",
	are_you_sure_to_create_your_employer_account_and_accept_3_basic_rules_:
		"您確認要直接開始使用，並同意三定律？",

	// candidate information
	resume___cv_file: "履歷檔案",
	reference_link: "參考連結",
	no_resume___cv_file: "沒有履歷檔案",

	// time unit
	_hour_ago: "小時前",
	_hours_ago: "小時前",
	_minute_ago: "分鐘前",
	_minutes_ago: "分鐘前",
	_day_ago: "天前",
	_days_ago: "天前",

	// editor
	bold: "粗體",
	heading: "標題",
	bulleted_list: "要點項目",
	numbered_list: "編號項目",
	hyperlink: "超連結",

	// TODO: error messages will be place at ./error_messages.js in the future
	// error messages
	required: "必填",
	length_of_text_limit__180_characters: "最多 180 個字元",
	length_of_text_limit__6000_characters: "最多 6000 個字元",
	upload_size_limit__30MB: "檔案大小上限： 30MB",
	invalid_email_format: "您的 Email 格式不正確",
	end_time_must_be_greater_than_start_time: "結束時間必須比開始時間晚",
	the_passwords_you_entered_do_not_match: "您輸入的兩個密碼不相同",
	password_contains_at_least_8_characters_is_required:
		"請輸入至少8個字元的密碼",
	invalid_slug_format: "您的 Slug 格式不正確",
	must_be_a_number: "必須為數字",
	must_be_greater_than_or_equal_to_minimum_salary: "必須大於或等於最小薪資",

	// notice messages
	link_is_copied: "推薦連結已被複製",

	open_resume_for_employers:
		"同步開放給參加 {{event_name}} 的企業雇主搜尋履歷並邀請面試",
	same_setting_as_upload_resume:
		"*此項設定與「上傳履歷」中的「開放企業搜尋履歷」設定同步",
	open_for_searching: "開放企業搜尋履歷",
};
