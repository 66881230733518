import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
	HeaderWrapper,
	SidebarWrapper,

	// page
	IndexPageWrapper,
	JobsPageWrapper,
	JobPageWrapper,
	CreateJobPageWrapper,
	ApplicationsPageWrapper,
	EmployerProfilePageWrapper,
	MemberManagementPageWrapper,
	UpgradePlansPageWrapper,
	PaymentPageWrapper,
	InvoicesPageWrapper,

	// modal
	EmployerMessageModalWrapper,
	CreateEmployerModalWrapper,
	AddMemberModalWrapper,
	EditMemberModalWrapper,
	EditInvitedMemberModalWrapper,
	ContactMeetJobsModalWrapper,
	WelcomeEmployerModalWrapper,
	LockedModalWrapper,
} from "./../../containers/dashboard";
import Employer from "../../models/employer";
import InternalUri from "../../models/internal_uri";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			match,
			dashboard_sidebar_collapsed,
			current_user,
			reduxTokenAuth,
			location,
		} = this.props;
		const internal_uri = new InternalUri();

		if (!reduxTokenAuth.currentUser.isSignedIn) {
			const redirectTo = `${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
				location.pathname
			}${location.search}`;
			return <Redirect to={redirectTo} />;
		} else if (current_user.companies.length === 0) {
			const redirectTo = internal_uri.formatted_frontend_create_employer_page_path();
			return <Redirect to={redirectTo} />;
		}

		if (
			Object.keys(current_user.active_company).length === 0 ||
			current_user.companies.length === 0
		)
			return null;

		const active_employer = new Employer(current_user.active_company);

		return (
			<div
				className={`Dashboard${
					dashboard_sidebar_collapsed ? " sidebar-collapsed" : ""
				}`}>
				{!active_employer.is_locked() ? (
					<div>
						<HeaderWrapper match={match} />
						<div className="content-wrapper">
							<div className="dashboard-sidebar-wrapper">
								<SidebarWrapper match={match} />
							</div>
							<div className="dashboard-content-wrapper">
								<Switch>
									<Route
										exact
										path={`${match.url}/`}
										render={() => <IndexPageWrapper />}
									/>
									<Route
										exact
										path={`${match.url}/jobs`}
										render={() => <JobsPageWrapper />}
									/>
									<Route
										path={`${match.url}/jobs/job/:id/:mode`}
										render={() => <JobPageWrapper />}
									/>
									<Route
										path={`${match.url}/jobs/create-job`}
										render={() => <CreateJobPageWrapper />}
									/>
									<Route
										path={`${match.url}/applications`}
										render={() => <ApplicationsPageWrapper />}
									/>
									<Route
										path={`${match.url}/employer-profile`}
										render={() => <EmployerProfilePageWrapper />}
									/>
									<Route
										path={`${match.url}/payment-setting`}
										render={() => <PaymentPageWrapper />}
									/>
									<Route
										path={`${match.url}/invoices`}
										render={() => <InvoicesPageWrapper />}
									/>
									<Route
										path={`${match.url}/member-management`}
										render={() => <MemberManagementPageWrapper />}
									/>
									<Route
										path={`${match.url}/upgrade-plans`}
										render={() => <UpgradePlansPageWrapper />}
									/>
								</Switch>
							</div>
						</div>
						<EmployerMessageModalWrapper />
						<AddMemberModalWrapper />
						<EditMemberModalWrapper />
						<EditInvitedMemberModalWrapper />
						<WelcomeEmployerModalWrapper />
					</div>
				) : (
					<div>
						<HeaderWrapper match={match} />
						<div className="content-wrapper">
							<div className="dashboard-sidebar-wrapper">
								<SidebarWrapper match={match} />
							</div>
							<div className="dashboard-content-wrapper">
								<Switch>
									<Route
										exact
										path={`${match.url}/`}
										render={() => <IndexPageWrapper />}
									/>
									{active_employer.is_latest_plan_post() ? (
										<Route
											path={`${match.url}/payment-setting`}
											render={() => <PaymentPageWrapper />}
										/>
									) : (
										""
									)}
									{active_employer.is_latest_plan_post() ? (
										<Route
											path={`${match.url}/upgrade-plans`}
											render={() => <UpgradePlansPageWrapper />}
										/>
									) : (
										""
									)}
									<Route path={`${match.url}/`} render={() => <div />} />
								</Switch>
							</div>
						</div>
						<LockedModalWrapper />
					</div>
				)}
				<ContactMeetJobsModalWrapper />
				<CreateEmployerModalWrapper />
			</div>
		);
	}
}

export default Dashboard;
