export default {
    extend_all_posts: "延長所有刊登",
    extend: "延長刊登",
    title: "工作機會名稱",
    salary: "薪資",
    applications: "應徵數",
    status: "狀態",
    expire_in___days: "將在 {{days_left}} 天內過期",
    expire_in_1_day: "將在 1 天內過期",
    expired: "已過期",
    expired___days_ago: "已過期 {{days_left}} 天",
    expired_1_day_ago: "已過期 1 天",
    no_jobs_posted: "無張貼的工作機會",
    you_haven_t_posted_any_jobs_yet_: "您還沒張貼任何工作機會",
    are_you_sure_to_post_this_draft_: "您確定要立即張貼這筆工作機會？",
    are_you_sure_to_close_this_job_: "您確定要關閉這筆工作機會？",
    are_you_sure_to_delete_this_draft_: "您確定要刪除這筆草稿？",
};