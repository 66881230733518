import React from "react";
import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import i18n from "../../../../i18n";
import Config from "../../../models/config";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Select from "react-select";
import { change_language_with_redirect } from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class Sidebar extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.handleSubMenu = this.handleSubMenu.bind(this);
		this.toggleSidebarCollapsed = this.toggleSidebarCollapsed.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.updateSubMenuTopPosition = this.updateSubMenuTopPosition.bind(this);
		this.state = {
			payment_sub_menu: false,
			language_sub_menu: false,
			access_control_list: {},
			language: "en",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		document
			.querySelector(`.dashboard-sidebar-wrapper ul.sidebar`)
			.addEventListener("scroll", this.updateSubMenuTopPosition);
		window.addEventListener("resize", this.updateSubMenuTopPosition);

		const { current_user } = this.props;
		this.setState(
			(prev_state) => ({
				...prev_state,
				access_control_list:
					current_user.employer_roles[current_user.active_company.id]
						.access_control_list,
				language: i18n.language,
			}),
			() => this.updateSubMenuTopPosition(),
		);
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		this.setState({ language: i18n.language });

		const { current_user } = next_props;
		if (
			JSON.stringify(this.props.current_user) !== JSON.stringify(current_user)
		) {
			this.setState((prev_state) => ({
				...prev_state,
				access_control_list:
					current_user.employer_roles[current_user.active_company.id]
						.access_control_list,
			}));
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		document
			.querySelector(`.dashboard-sidebar-wrapper ul.sidebar`)
			.removeEventListener("scroll", this.updateSubMenuTopPosition);
		window.removeEventListener("resize", this.updateSubMenuTopPosition);
	}

	handleSubMenu = (e, property_name) => {
		e.preventDefault();
		const cur_state = this.state[property_name];
		this.setState({
			[property_name]: !cur_state,
		});
	};

	toggleSidebarCollapsed = (e) => {
		e.preventDefault();
		this.props.updateDashboardSidebarCollapsed(
			!this.props.site_state.dashboard_sidebar_collapsed,
		);
	};

	handleSelectChange = (value) => {
		this.setState({ language: value });
		change_language_with_redirect(value, this.props.history);

		const { reduxTokenAuth } = this.props;
		if (reduxTokenAuth.currentUser.isSignedIn) {
			let formData = new FormData();
			formData.append("user[locale]", value);
			let options = {
				method: "PUT",
				url: config.api_url(
					`/users/${reduxTokenAuth.currentUser.attributes.id}`,
				),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);
				})
				.catch((error) => {
					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					if (this._isMounted) {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);
					}
				});
		}
	};

	updateSubMenuTopPosition = () => {
		const lists = ["payment", "language", "collapse"];
		for (let i = 0; i < lists.length; i++) {
			let li = document.querySelector(
				`.dashboard-sidebar-wrapper ul.sidebar li.li-${lists[i]}`,
			);
			let sub_menu = document.querySelector(
				`.dashboard-sidebar-wrapper ul.sidebar li.li-${lists[i]} ul.sub-menu`,
			);
			if (li && sub_menu) {
				sub_menu.style.top = li.getBoundingClientRect().top + "px";
			}
		}
	};

	render() {
		const { site_state } = this.props;
		const { payment_sub_menu, access_control_list, language } = this.state;
		const { handleSubMenu, toggleSidebarCollapsed, handleSelectChange } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();
		const date = new Date();

		return (
			<ul
				className={`sidebar main-menu${
					site_state.dashboard_sidebar_collapsed ? " collapsed" : ""
				}`}>
				<li className="li-home">
					<NavLink
						exact
						to={internal_uri.formatted_dashboard_index_page_path()}>
						<p>{t("home")}</p>
					</NavLink>
				</li>
				{access_control_list.jobs_manageable ? (
					<li className="li-jobs">
						<NavLink to={internal_uri.formatted_dashboard_jobs_page_path()}>
							<p>{t("jobs")}</p>
						</NavLink>
					</li>
				) : (
					""
				)}
				{access_control_list.job_applications_manageable ? (
					<li className="li-applications">
						<NavLink
							to={internal_uri.formatted_dashboard_applications_page_path()}>
							<p>{t("applications")}</p>
						</NavLink>
					</li>
				) : (
					""
				)}
				{access_control_list.setting_manageable ? (
					<li className="li-company">
						<NavLink
							to={internal_uri.formatted_dashboard_employer_profile_page_path()}>
							<p>
								{t("employer_profile")}
								{this.props.site_state
									.employer_profile_pending_degree_of_completion !== null &&
								typeof this.props.site_state
									.employer_profile_pending_degree_of_completion !==
									"undefined" ? (
									<span className="counting-block counting-block-orange">
										{
											this.props.site_state
												.employer_profile_pending_degree_of_completion
										}
										%
									</span>
								) : this.props.current_user.active_company
										.employer_profile_degree_of_completion &&
								  this.props.current_user.active_company
										.employer_profile_degree_of_completion !== 100 ? (
									<span className="counting-block counting-block-orange">
										{
											this.props.current_user.active_company
												.employer_profile_degree_of_completion
										}
										%
									</span>
								) : (
									""
								)}
							</p>
						</NavLink>
					</li>
				) : (
					""
				)}
				{access_control_list.plan_manageable &&
				access_control_list.billing_manageable ? (
					<li
						className={`li-payment${payment_sub_menu ? " open-sub-menu" : ""}`}>
						<a
							href="#payment_and_invoices"
							onClick={(e) => handleSubMenu(e, "payment_sub_menu")}>
							<p>{t("payment_and_invoices")}</p>
						</a>
						<ul className="sub-menu">
							<li>
								<NavLink
									to={internal_uri.formatted_dashboard_invoices_page_path()}>
									<p>{t("invoices")}</p>
								</NavLink>
							</li>
							<li>
								<NavLink
									to={internal_uri.formatted_dashboard_payment_setting_page_path()}>
									<p>{t("payment_setting")}</p>
								</NavLink>
							</li>
						</ul>
					</li>
				) : (
					""
				)}
				<li className="li-people">
					<NavLink
						to={internal_uri.formatted_dashboard_member_management_page_path()}>
						<p>{t("member_management")}</p>
					</NavLink>
				</li>
				<li className="li-space" />
				<li className="li-collapse">
					<a href="/#collapse_menu" onClick={(e) => toggleSidebarCollapsed(e)}>
						<p>{t("collapse_menu")}</p>
					</a>
					<ul className="sub-menu">
						<li>
							<a
								href="/#expand_menu"
								onClick={(e) => toggleSidebarCollapsed(e)}>
								<p>{t("expand_menu")}</p>
							</a>
						</li>
					</ul>
				</li>
				<li className={`li-language`}>
					<a
						href="/#language_sub_menu"
						onClick={(e) => handleSubMenu(e, "language_sub_menu")}>
						<div className="select-wrapper">
							<Select
								className="drop-down drop-down-up drop-down-larger drop-down-no-border"
								clearable={false}
								searchable={false}
								value={language}
								onChange={(selected_option) =>
									handleSelectChange(selected_option.value)
								}
								options={[
									{ value: "en", label: "English" },
									{ value: "zh-TW", label: "繁體中文" },
								]}
							/>
						</div>
					</a>
					<ul className="sub-menu">
						<li>
							<a
								href="/#en"
								className={language === "en" ? "active" : ""}
								onClick={(e) => {
									e.preventDefault();
									handleSelectChange("en");
								}}>
								<p>English</p>
							</a>
						</li>
						<li>
							<a
								href="/#zhTW"
								className={language === "zh-TW" ? "active" : ""}
								onClick={(e) => {
									e.preventDefault();
									handleSelectChange("zh-TW");
								}}>
								<p>繁體中文</p>
							</a>
						</li>
					</ul>
				</li>
				<li className="li-copyright">
					<h5>{t("copyright___meet_jobs__", { year: date.getFullYear() })}</h5>
				</li>
				<li>
					<h5>{t("taipei_number")}</h5>
				</li>
			</ul>
		);
	}
}

export default withNamespaces(["general"])(Sidebar);
