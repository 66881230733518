import React from "react";
import {Link} from "react-router-dom";
import InternalUri from "../../../../models/internal_uri";
import {ReactComponent as Icon} from "../../../../../assets/images/icon/icon_24_jobs_white.svg";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import ApiHeaders from "../../../../models/api_headers";
import Config from "../../../../models/config";

const config = new Config();
const headers = new ApiHeaders();

class JobSummaryCard extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {
            employerId: -1,
            job_count: {
              opening: null,
              total: null
            },
            loading: false,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ employerId: this.props.employerId }, () => this.fetchData());
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.employerId !== next_props.employerId) {
            this.setState({ employerId: next_props.employerId }, () => this.fetchData());
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchData() {
        this.setState({ loading: true });

        const { employerId } = this.state;
        let options, promises = [];
        options = {
            method: 'GET',
            url: config.api_url(`/employers/${employerId}/jobs/state/all?per_page=1`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        promises.push(axios(options));
        options = {
            method: 'GET',
            url: config.api_url(`/employers/${employerId}/jobs/state/published?per_page=1`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        promises.push(axios(options));

        axios.all(promises)
            .then((results) => {
                for(let i=0; i<results.length; i++) {
                    headers.updateItemsToLocalStorage(results[i].headers);
                }

                if(this._isMounted) {
                    this.setState({
                        job_count: {
                            opening: results[1].data.paginator.total_entries,
                            total: results[0].data.paginator.total_entries
                        },
                        loading: false,
                        error_messages: {}
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            });
    };

    render() {

        const { loading, job_count } = this.state;
        const internal_uri = new InternalUri();
        const { t } = this.props;

        if(loading) {
            return (
                <div className="loading-skeleton-wrapper">
                    <div className="col-md-4">
                        <div className="summary-card block">
                            <h4>{t('general:jobs')}</h4>
                            <Icon className="icon-bg" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-md-4">
                <div className="summary-card block">
                    <h4>{t('general:jobs')}</h4>
                    <h3 className="content">{t('summaryCard.job.content', { number: job_count.opening })}</h3>
                    <p>{t('summaryCard.job.subContent', { number: job_count.total })}</p>
                    <Icon className="icon-bg" />
                    <Link
                        to={internal_uri.formatted_dashboard_create_job_page_path()}
                        className="btn btn-smallest btn-fill btn-flat"
                    >
                        <h5>{t('general:post_a_job')}</h5>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withNamespaces(['dashboard_index_page'])(JobSummaryCard);