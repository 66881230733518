export default {
	country: "Country",
	contract_file_name: "Meetjobs_BusinessUserAgreement_en_20230219.pdf",

	meet_jobs___global_talent__global_job: "Meet.jobs｜Global Talent, Global Job",
	refer_your_friends_for: "Refer your friends for",
	be_a_referrer: "Be A Referrer",

	meet_jobs_introduction_content:
		"Meet.jobs is a headhunter platform focusing on international talents and jobs. The mission of Meet.jobs is to help everyone to maximize one’s own value in the world! Meet.jobs believes in Sharing, Trust, and Diversity, reflected on the characters of Community Referral, Information Transparency, and International-Focused.",
	employer_landing_description:
		"High-quality and efficient recruitment service with competitive price. Meet.jobs is a headhunting platform leveraging on social referral and community sharing and focusing on international talents and job opportunities. Comprehensive information is not only fair to candidates, but also enhancing recruitment efficiency of employers. Meet.jobs insists all job vacancies on the platform, not only “negotiable”, must have salary ranges. Trust is the core of recruitment. With open information, talents shall swiftly select job opportunities; therefore employers can get only effective applications.",
	referrer_landing_description:
		"You can refer jobs to your friends as well as searching for yourself. Meet.jobs provides “Referral Reward”, about 4% of the annual salary, to the “referrer” recommending candidates get hired successfully! If you get hired through Meet.jobs, you will receive US$100 “Onboard Reward”. In return, you could share your cross-cultural working and living experience, not to help Meet.jobs, but other job seekers.",

	all__jobs_: "All{{- _q}} Jobs{{- _in_place}}",
	browse_all___jobs___meet_jobs_is_a_headhunter_platform_focusing_on_international_talents_and_jobs_:
		"Browse all{{- _q}} jobs{{- _near_place}}. Meet.jobs is a headhunter platform focusing on international talents and jobs.",
	_q: " {{- keyword_q}}",
	_in_place: " in {{- keyword_place}}",
	_near_place: " near {{- keyword_place}}",

	loading: "Loading",
	e_g__: "e.g.:",
	or: "or",
	plus: "Plus",
	also: "also",

	referral_reward: "Referral Reward",
	onboard_reward: "Onboard Reward",

	featured: "Featured",
	post: "Post",
	search: "Search",
	edit: "Edit",
	edit_draft: "Edit draft",
	close: "Close",
	closed: "Closed",
	update: "Update",
	delete: "Delete",
	reopen: "Reopen",
	remove: "Remove",
	leave: "Leave",
	save: "Save",
	saved: "Saved",
	saving: "Saving",
	cancel: "Cancel",
	publish: "Publish",
	save_draft: "Save draft",
	discard: "Discard",
	upgrade: "Upgrade",
	download: "Download",
	upload: "Upload",
	publishing: "Publishing",
	learn_more: "Learn more",
	see_all: "See all",
	recent: "Recent",
	post_a_job: "Post a job",
	current_plan_: "Current plan:",
	clear: "Clear",
	ok: "OK",
	yes: "Yes",
	choose_file: "Choose file",
	copy: "Copy",
	copy_link: "Copy link",
	share_link: "Share Job Link",
	submit_for_friend: "Submit for Friend",
	copy_code: "Copy code",
	copy_referral_link: "Copy referral link",
	referral_link: "Referral link",
	copied: "Copied",
	apply: "Apply",
	invite_to_apply: "Invite to apply",
	invitation_to_apply: "Invitation to apply",
	i_accept: "I accept",
	accept: "Accept",
	_not_provided_: "(Not provided)",
	__optional_: " (Optional)",
	refer_this_job: "Refer this job",
	refer_this_employer: "Refer this employer",
	view_on_map: "View on map",
	send: "Send",
	sending: "Sending",
	applied_at: "Applied at",
	updated_at: "Updated at",
	applied: "Applied",
	updated: "Updated",
	updated_on: "Updated on",
	closed_on: "Closed on",
	due_on: "Due on",
	awaiting_confirmation: "Awaiting confirmation",
	external_website: "External website",
	_messages: " Messages",
	messages: "Messages",
	details: "Details",
	comments: "Comments",
	reference_letter: "Reference",
	ref__letter: "Reference",
	no_more_messages: "No more messages",
	no_more_comments: "No more comments",
	no_more_reference_letter: "No more reference",
	read: "Read",
	write: "Write",
	claim: "Claim",
	add: "Add",
	add_file: "Add file",
	continue: "Continue",
	confirm: "Confirm",
	confirmed: "Confirmed",
	cancelled: "Cancelled",
	reject: "Thank-you letter",
	rejected: "Not selected",
	decline: "Decline",
	next: "Next",
	referred: "Referred",
	you_don_t_have_the_proper_privilege_level_to_visit_this_page_:
		"You don't have the proper privilege level to visit this page.",
	last_revised_on: "Last revised on",
	pending: "Pending",
	expired: "Expired",
	job_function: "Job function",

	// header、sidebar
	jobs: "Jobs",
	referrer: "Referrer",
	employer: "Employer",
	column: "Column",
	job__company__or_keyword: "Job, Company, or Keyword",
	keyword: "Keyword",
	city_or_country: "City or Country",
	log_in: "Log in",
	sign_up: "Sign up",
	sign_up_with_email: "Sign up with email",
	log_in_with_facebook: "Log in with facebook",
	sign_up_with_facebook: "Sign up with facebook",
	account_setting: "Account setting",
	my_applications: "My applications",
	saved_jobs: "Saved jobs",
	my_referrals: "My referrals",
	my_resume: "My resume",
	upload_resume: "Upload CV",
	default_resume: "Default resume",
	view_default_resume: "View default resume",
	upload_new_resume: "Upload a resume",
	upload_new_one: "Upload a new one",
	use_default_resume: "Use default resume",
	use_another_resume: "Use another resume",
	set_as_default_resume: "Set as default",
	file_name: "File name",
	get_reward: "Get reward",
	log_out: "Log out",
	home: "Home",
	home_to_index: "Home",
	back: "Back",
	job_list: "Job list",
	applications: "Applications",
	employer_profile: "Employer profile",
	payment_and_invoices: "Payment & Invoices",
	invoices: "Invoices",
	payment_setting: "Payment Setting",
	upgrade_plans: "Upgrade plans",
	member_management: "Member management",
	back_to_meet_jobs: "Back to meet.jobs",
	create_employer: "Create Employer",
	copyright___meet_jobs__: "Copyright © Meet.jobs {{year}}",
	taipei_number: "北市就服字 第0304號",
	your_list_is_empty_: "Your list is empty.",
	collapse_menu: "Collapse menu",
	expand_menu: "Expand menu",

	// footer
	about_us: "About us",
	brand: "Brand",
	contact_us: "Contact us",
	all_jobs: "All Jobs",
	hello__meet_jobs__i_have_some_questions___:
		"Hello, Meet.jobs! I have some questions...",

	// privacy and terms
	privacy_policy: "Privacy Policy",
	employer_agreement: "Employer Agreement",
	cookie_policy: "Cookie Policy",
	user_agreement: "User Agreement",
	summary_of_changes: "Summary of Changes",
	privacy_and_terms: "Privacy & Terms",

	// plans
	plan_post: "Post",
	plan_hire: "Social",
	plan_hire_: "Hunter",

	social_referral_name: "Social Plan",
	custom_referral_name: "Hunter Plan",

	us__30: "US $30",
	each_job_post_per_month: "Each job post per month",
	applicant_tracking: "Applicant tracking",
	seo_friendly: "SEO-friendly",
	direct_message: "Direct message",

	commnuity_referral_quick_and_precise:
		"Community Referral, Quick and Precise!",
	emplouyer_brandind_and_dynamic_promotion:
		"Employer Branding and Dynamic Promotion",
	integrated_marketing_and_speed_interview_event:
		"Integrated Marketing and Speed Interview Event",

	everything_in_hire_plan: "Everything in Hire Plan",
	recruitment_consulting_service: "Recruitment Consulting Service",
	everything_in_social_plan: "Everything in Social Plan",
	professional_hunter_search: "Professional Hunter Search",
	double_screening_ai: "Double Screening, A.I. + Headhunter",
	professional_slill_language_adaptive_tests:
		"Professional Skill / Language / Adaptive Tests",
	customized_service_contract: "Customized Service and Contract (20%↑)",

	recommend: "Recommend",
	_8__annual_salary: "8% Annual Salary",
	per_successful_hire: "Per successful hire",
	everything_in_post__plus_: "Everything in Post, plus:",
	social_referral: "Social referral",
	crowd_sourcing: "Crowd sourcing",
	peer_review: "Peer review",
	employer_branding: "Employer branding",
	integrated_marketing: "Integrated marketing",
	_30_day_probation: "30-day probation",

	_16__annual_salary: "16% Annual Salary",
	_20__annual_salary: "20%⬆ Annual Salary",
	__annual_salary: "{{ rate }}% Annual Salary",

	everything_in_hire__plus_: "Everything in Hire, plus:",
	_180_day_probation: "180-day probation",
	recruitment_consulting: "Recruitment consulting",
	customized_contract: "Customized contract",

	select_plan: "Select plan",
	current_plan: "Current plan",
	selected: "Selected",

	// three basic rules
	three_basic_rules: "3 Basic Rules",
	payment_by_successful_hire___of_annual_salary_:
		"Payment by successful hire ({{number}}% of annual salary)",
	___day_probation_guarantee: "{{number}}-day probation guarantee",
	salary_information_is_mandatory_: "Salary information is mandatory!",

	payment_by_successful_use_hire_or_hire_plus:
		"Payment by successful hire ( Social or Hunter plan)",

	// log out modal
	is_that_you_: "Is that you?",
	we_notice_that_you_re_already_logged_in_as_different_account_would_you_like_to_proceed_:
		"We notice that you’re already logged in as different account. Would you like to proceed?",

	// connection off modal
	oops_: "Oops!",
	unable_to_connect_to_meet_jobs: "Unable to connect to Meet.jobs.",

	// external job redirect page
	redirect_in___seconds: "Redirect in {{remaining_seconds}} seconds",
	please_apply_in_the_redirected_page__thank_you_:
		"Please apply in the redirected page, thank you.",

	// create employer form
	profile: "Profile",
	choose_plan: "Choose plan",
	agreement: "Agreement",
	create_employer_account: "Create employer account",
	set_up_profile: "Set up profile",
	employer_name: "Employer name",
	your_employer_name: "Your employer name",
	contact_name: "Contact name",
	full_name: "Full name",
	contact_phone_number: "Contact phone number",
	your_phone_number: "Your phone number",
	location: "Location",
	city: "City",
	your_domain_on_meet_jobs: "Your domain on Meet.jobs",
	_only_letters_numbers_and_hyphens_are_permitted_:
		"(Only letters, numbers, and hyphens are permitted)",
	_employer_name: "employer-name",
	how_did_you_hear_about_us_: "How did you hear about us?",
	a_person_s_name___contact: "A person's name & contact",

	// contract
	employer_user_agreement: "Employer User Agreement",
	i_accept_meet_jobs_employer_user_agreement:
		"I accept Meet.jobs Employer User Agreement",
	i_accept_meet_jobs_3_basic_rules_and_employer_user_agreement:
		"I accept Meet.jobs’ 3 Basic Rules & Employer User Agreement",
	use_first__contract_later__: "Use first, contract later →",
	are_you_sure_to_create_your_employer_account_and_accept_3_basic_rules_:
		"Are you sure to create your employer account and Accept 3 Basic Rules?",

	// candidate information
	resume___cv_file: "Resume / CV file",
	no_resume___cv_file: "No resume / CV file",
	reference_link: "Reference link",

	// time unit
	_hour_ago: " hour ago",
	_hours_ago: " hours ago",
	_minute_ago: " minute ago",
	_minutes_ago: " minutes ago",
	_day_ago: " day ago",
	_days_ago: " days ago",

	// editor
	bold: "Bold",
	heading: "Heading",
	bulleted_list: "Bulleted list",
	numbered_list: "Numbered list",
	hyperlink: "Hyperlink",

	// TODO: error messages will be place at ./error_messages.js in the future
	// error messages
	required: "Required",
	length_of_text_limit__180_characters: "Length of text limit: 180 characters",
	length_of_text_limit__6000_characters:
		"Length of text limit: 6000 characters",
	upload_size_limit__30MB: "Upload size limit: 30MB",
	invalid_email_format: "Invalid Email format",
	end_time_must_be_greater_than_start_time:
		"End time must be greater than start time",
	the_passwords_you_entered_do_not_match:
		"The passwords you entered do not match",
	password_contains_at_least_8_characters_is_required:
		"Password contains at least 8 characters is required",
	invalid_slug_format: "Invalid Slug format",
	must_be_a_number: "Must be a number",
	must_be_greater_than_or_equal_to_minimum_salary:
		"Must be greater than or equal to minimum salary",

	// notice messages
	link_is_copied: "Link is copied",
	open_resume_for_employers:
		"Open resume for employers attending event {{event_name}} to search and invite to interview",
	same_setting_as_upload_resume:
		"* Same setting as the one at “Upload Resume” > “Open for searching”",
	open_for_searching: "Open for searching",
};
