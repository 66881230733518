import general from './general.js';
import states from './states.js';
import flash_messages from './flash_messages.js';
import system_messages from './system_messages.js';
import error_messages from './error_messages.js';
import events from './events.js';

import frontend_index_page from './frontend/index_page.js';
import frontend_confirm_done_page from './frontend/confirm_done_page.js';
import frontend_confirm_page from './frontend/confirm_page.js';
import frontend_employer_landing_page from './frontend/employer_landing_page.js';
import frontend_employer_page from './frontend/employer_page.js';
import frontend_job_page from './frontend/job_page.js';
import frontend_job_invitation_page from './frontend/job_invitation_page.js';
import frontend_jobs_page from './frontend/jobs_page.js';
import frontend_refer_page from './frontend/refer_page.js';
import frontend_referral_landing_page from './frontend/referral_landing_page.js';
import frontend_employer_agreement_online_text from './frontend/employer_agreement_online_text.js';
import frontend_privacy_and_terms_page from './frontend/privacy_and_terms_page.js';
import frontend_authentication from './frontend/authentication.js';
import frontend_about_us_page from './frontend/about_us.js';
import frontend_error_404_page from './frontend/error_404_page.js';
import frontend_error_500_page from './frontend/error_500_page.js';
import frontend_modals from './frontend/modals.js';

import dashboard_index_page from './dashboard/index_page.js';
import dashboard_applications_page from './dashboard/applications_page.js';
import dashboard_employer_profile_page from './dashboard/employer_profile_page.js';
import dashboard_jobs_page from './dashboard/jobs_page.js';
import dashboard_job_page from './dashboard/job_page.js';
import dashboard_invoices_page from './dashboard/invoices_page.js';
import dashboard_payment_page from './dashboard/payment_page.js';
import dashboard_upgrade_plans_page from './dashboard/upgrade_plans_page.js';
import dashboard_member_management_page from './dashboard/member_management_page.js';
import dashboard_modals from './dashboard/modals.js'

import userpanel_account_setting from './userpanel/account_setting.js';
import userpanel_my_applications from './userpanel/my_applications.js';
import userpanel_my_referrals from './userpanel/my_referrals.js';
import userpanel_saved_jobs from './userpanel/saved_jobs.js';
import userpanel_modals from './userpanel/modals.js';

import talents_talent_page from "./talents/talent_page.js";

export default {

    general,
    states,
    flash_messages,
    system_messages,
    error_messages,
    events,

    // frontend
    frontend_index_page,
    frontend_confirm_done_page,
    frontend_confirm_page,
    frontend_employer_landing_page,
    frontend_employer_page,
    frontend_job_page,
    frontend_job_invitation_page,
    frontend_jobs_page,
    frontend_refer_page,
    frontend_referral_landing_page,
    frontend_employer_agreement_online_text,
    frontend_privacy_and_terms_page,
    frontend_authentication,
    frontend_about_us_page,
    frontend_error_404_page,
    frontend_error_500_page,
    frontend_modals,

    // dashboard
    dashboard_index_page,
    dashboard_applications_page,
    dashboard_employer_profile_page,
    dashboard_jobs_page,
    dashboard_job_page,
    dashboard_invoices_page,
    dashboard_payment_page,
    dashboard_upgrade_plans_page,
    dashboard_member_management_page,
    dashboard_modals,

    // userpanel
    userpanel_account_setting,
    userpanel_my_applications,
    userpanel_my_referrals,
    userpanel_saved_jobs,
    userpanel_modals,

    // talents
    talents_talent_page
};