import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { signOutUser } from "../../../../redux-token-auth-config";
import Employer from "../../../models/employer";
import User from "../../../models/user.js";
import { withNamespaces } from "react-i18next";
import logo from "../../../../assets/images/logo_meetjobs_standard.svg";
import icon_search from "../../../../assets/images/icon/icon_28_search_BG400.svg";
import InternalUri from "../../../models/internal_uri";
import { sendDataLayer } from "../../../../assets/js/dataLayer";
import DashboardMenuInner from "../../dashboard/DashboardMenuInner";
import RemindUpLoadResumeBanner from "./components/header/RemindUpLoadResumeBanner";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggleSearchBar = this.toggleSearchBar.bind(this);
		this.toggleMobileMainMenu = this.toggleMobileMainMenu.bind(this);
		this.toggleMobileSubMenu = this.toggleMobileSubMenu.bind(this);
		this.handleSubMenu = this.handleSubMenu.bind(this);
		this.openSignUpModal = this.openSignUpModal.bind(this);
		this.openLogInModal = this.openLogInModal.bind(this);
		this.submitSearchBar = this.submitSearchBar.bind(this);
		this.signOut = this.signOut.bind(this);
		this.state = {
			isRemindUploadResumeBannerOpen: true,
			active_company: {},
			active_user: {},
			validating: true,
		};
	}

	componentDidMount() {
		const { reduxTokenAuth, current_user } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			active_company: new Employer(current_user.active_company),
			active_user: new User(reduxTokenAuth.currentUser.attributes),
			validating: this.props.validating,
			isRemindUploadResumeBannerOpen: reduxTokenAuth.currentUser.attributes
				.default_resume_attachment
				? false
				: true,
		}));

		this.keyword.value = this.props.search_words.keyword;
		this.keyword_place.value = this.props.search_words.keyword_place;
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (next_props.search_words.keyword !== this.props.search_words.keyword) {
			this.keyword.value = next_props.search_words.keyword;
		}

		if (
			next_props.search_words.keyword_place !==
			this.props.search_words.keyword_place
		) {
			this.keyword_place.value = next_props.search_words.keyword_place;
		}

		const { reduxTokenAuth, current_user } = next_props;
		if (
			next_props.validating !== this.props.validating ||
			JSON.stringify(current_user) !==
				JSON.stringify(this.props.current_user) ||
			JSON.stringify(reduxTokenAuth.currentUser.attributes) !==
				JSON.stringify(this.props.reduxTokenAuth.currentUser.attributes)
		) {
			this.setState((prev_state) => ({
				...prev_state,
				validating: next_props.validating,
				active_company: new Employer(current_user.active_company),
				active_user: new User(reduxTokenAuth.currentUser.attributes),
			}));
		}
	}

	toggleSearchBar = (e) => {
		e.preventDefault();

		const { site_state, updateSearchBar, updateMobileMenu } = this.props;
		const cur_search_bar = site_state.search_bar;
		const cur_mobile_menu = site_state.mobile_menu;

		if (cur_search_bar) {
			updateSearchBar(false);
			this.keyword.blur();
			this.keyword_place.blur();
			return true;
		}

		if (cur_mobile_menu) {
			updateMobileMenu("");
			setTimeout(function() {
				updateSearchBar(true);
			}, 100);
		} else {
			updateSearchBar(true);
		}
		this.keyword.focus();
	};

	toggleMobileMainMenu = (e) => {
		e.preventDefault();

		const { site_state, updateMobileMenu, updateSearchBar } = this.props;
		const cur_search_bar = site_state.search_bar;
		const cur_mobile_menu = site_state.mobile_menu;

		if (cur_mobile_menu) {
			updateMobileMenu("");
			return true;
		}

		if (cur_search_bar) {
			updateSearchBar(false);
			setTimeout(function() {
				updateMobileMenu("main_menu");
			}, 100);
		} else {
			updateMobileMenu("main_menu");
		}
	};

	toggleMobileSubMenu = (e) => {
		e.preventDefault();
		const { site_state, updateMobileMenu } = this.props;

		const cur_mobile_menu = site_state.mobile_menu;
		cur_mobile_menu === "sub_menu"
			? updateMobileMenu("main_menu")
			: updateMobileMenu("sub_menu");
	};

	handleSubMenu = (e) => {
		e.preventDefault();
		this.props.updateSubMenu(!this.props.site_state.sub_menu);
	};

	openSignUpModal = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("sign-up");
	};

	openLogInModal = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("log-in");
	};

	submitSearchBar = (e) => {
		e.preventDefault();
		const internal_uri = new InternalUri();
		this.props.updateSearchKeywords(
			this.keyword.value,
			this.keyword_place.value,
		);
		if (
			this.props.location.pathname.search("/jobs") === -1 || // not jobs page
			(this.props.location.pathname.search("/jobs") !== -1 && // is job page
				this.props.location.pathname.search("/jobs/") !== -1)
		) {
			this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
		}
	};

	signOut = (e) => {
		e.preventDefault();
		const { signOutUser } = this.props;
		signOutUser()
			.then(() => {
				this.props.updateConnectionOffModal(false);
				this.props.closeAll();
				sendDataLayer("userId", -1);
			})
			.catch((error) => {
				if (typeof error.response === "undefined")
					this.props.updateConnectionOffModal(true);

				if (error.response) {
					if (error.response.status === 404) {
						window.location.reload();
					}
				}
			});
	};

	render() {
		// console.log("header", this.props);
		const { reduxTokenAuth, site_state, current_user } = this.props;
		const { active_user, active_company, validating } = this.state;
		const {
			toggleSearchBar,
			toggleMobileMainMenu,
			toggleMobileSubMenu,
			handleSubMenu,
			openSignUpModal,
			openLogInModal,
		} = this;
		const { submitSearchBar, signOut } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		const login =
			reduxTokenAuth.currentUser.isSignedIn &&
			Object.keys(active_user).length > 0;
		const referral =
			reduxTokenAuth.currentUser.isSignedIn && current_user.is_referral_user;
		const employer =
			reduxTokenAuth.currentUser.isSignedIn &&
			current_user.is_employer_user &&
			Object.keys(active_company).length > 0;

		return (
			<header
				className={
					"general" +
					`${site_state.search_bar ? " open-search-bar" : ""}` +
					`${site_state.keep_search_bar ? " keep-open-search-bar" : ""}` +
					`${
						site_state.mobile_menu === "sub_menu"
							? " open-main-menu open-sub-menu"
							: ""
					}` +
					`${site_state.mobile_menu === "main_menu" ? " open-main-menu" : ""}`
				}>
				<div className="navigation-wrapper">
					<div className="container">
						<div className="navigation">
							<div className="logo-wrapper">
								<Link
									to={internal_uri.formatted_frontend_index_page_path()}
									data-event-category="000_meetjobs_logo"
								/>
								<img
									src={logo}
									alt="meet.jobs logo"
									onClick={(e) => toggleMobileMainMenu(e)}
									data-event-category="020_mobile_logo_dropdown"
								/>
							</div>
							<div
								className="search-trigger"
								onClick={(e) => toggleSearchBar(e)}
								data-event-category="001_nav_search_icon">
								<img src={icon_search} alt="" />
							</div>
							<ul className="main-menu hidden-sm hidden-xs">
								<li>
									<NavLink
										to={internal_uri.formatted_frontend_jobs_page_path()}
										data-event-category="002_nav_all_jobs"
										onClick={(e) => {
											e.preventDefault();
											this.props.updateSearchKeywords("", "");
											this.props.history.push(
												internal_uri.formatted_frontend_jobs_page_path(),
											);
										}}>
										<h5>{t("all_jobs")}</h5>
									</NavLink>
								</li>
								<li>
									<NavLink
										to={internal_uri.formatted_frontend_referrer_landing_page_path()}
										data-event-category="003_nav_referrer">
										<h5>{t("referrer")}</h5>
									</NavLink>
								</li>
								<li>
									<NavLink
										to={internal_uri.formatted_frontend_employer_landing_page_path()}
										data-event-category="004_nav_employer">
										<h5>{t("employer")}</h5>
									</NavLink>
								</li>
								<li>
									<a
										href="https://column.meet.jobs"
										data-event-category="005_nav_column">
										<h5>{t("column")}</h5>
									</a>
								</li>
								{validating ? (
									<li className="sub-menu-trigger-wrapper loading-skeleton-wrapper">
										<a
											href="/#submenu"
											onClick={(e) => e.preventDefault()}
											className="sub-menu-trigger">
											<div className="avatar">
												<div className="image-wrapper" />
											</div>
											<h5>
												<span className="text text-80">{`${t(
													"loading",
												)}...`}</span>
											</h5>
										</a>
									</li>
								) : !login ? (
									<span>
										<li>
											<a
												onClick={(e) => openLogInModal(e)}
												href="/#login"
												data-event-category="031_nav_log_in">
												<h5>{t("log_in")}</h5>
											</a>
										</li>
										<li>
											<a
												href="/#signup"
												onClick={(e) => openSignUpModal(e)}
												className="btn btn-flat btn-hollow"
												data-event-category="032_nav_sign_up">
												<h5>{t("sign_up")}</h5>
											</a>
										</li>
									</span>
								) : (
									<li
										className={`sub-menu-trigger-wrapper ${
											site_state.sub_menu ? "open-sub-menu" : ""
										}`}>
										<a
											className="sub-menu-trigger"
											href="/#"
											onClick={(e) => handleSubMenu(e)}>
											<div className="avatar">
												<div
													className="image-wrapper"
													style={{
														backgroundImage: `url(${active_user.formatted_avatar_toolbar_url()})`,
													}}
												/>
											</div>
											<h5>{active_user.formatted_name()}</h5>
										</a>
										<ul className="sub-menu">
											{employer ? (
												<li className="with-logo with-border-bottom">
													<Link
														to={internal_uri.formatted_dashboard_index_page_path()}
														data-event-category="011_employer_dashboard">
														<div className="logo">
															<div
																className="image-wrapper"
																style={{
																	backgroundImage: `url('${active_company.formatted_logo_toolbar_url()}')`,
																}}
															/>
														</div>
														<h6>{active_company.formatted_name()}</h6>
													</Link>
												</li>
											) : (
												""
											)}
											{DashboardMenuInner.map(
												({ name, path, category }, idx) => (
													<li
														key={idx}
														className={`${
															!referral && name === "my_referrals"
																? "with-btn"
																: null
														}`}>
														<NavLink
															exact
															to={internal_uri[path]()}
															data-event-category={category.header}>
															<h6>{t(name)}</h6>
														</NavLink>
														{!referral && name === "my_referrals" ? (
															<NavLink
																exact
																to={internal_uri.formatted_frontend_referrer_landing_page_path()}
																className="btn btn-smallest btn-flat btn-fill">
																<h6>{t("get_reward")}</h6>
															</NavLink>
														) : null}
													</li>
												),
											)}
											<hr />
											<li>
												<a
													onClick={(e) => signOut(e)}
													href="/#"
													data-event-category="016_log_out">
													<h6>{t("log_out")}</h6>
												</a>
											</li>
										</ul>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className="search-bar-wrapper">
					<div className="container">
						<div className="search-bar">
							<form onSubmit={submitSearchBar}>
								<input
									ref={(input) => (this.keyword = input)}
									className="input"
									type="text"
									placeholder={t("job__company__or_keyword")}
								/>
								<input
									ref={(input) => (this.keyword_place = input)}
									className="input"
									type="text"
									placeholder={t("city_or_country")}
								/>
								<button className="btn btn-larger btn-flat btn-fill">
									<h5>{t("search")}</h5>
								</button>
							</form>
						</div>
					</div>
				</div>
				<div className="mobile-main-menu-wrapper">
					<div className="container">
						<div className="mobile-main-menu">
							<ul>
								{login ? (
									<li className="with-avatar with-user-avatar with-border-bottom">
										<a href="/#submenu" onClick={(e) => toggleMobileSubMenu(e)}>
											<div className="avatar">
												<div
													className="image-wrapper"
													style={{
														backgroundImage: `url(${active_user.formatted_avatar_toolbar_url()})`,
													}}
												/>
											</div>
											<h5>{active_user.formatted_name()}</h5>
										</a>
									</li>
								) : (
									""
								)}
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_frontend_index_page_path()}
										data-event-category="021_mobile_nav_home">
										<h5>{t("home_to_index")}</h5>
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_frontend_jobs_page_path()}
										data-event-category="002_nav_all_jobs"
										onClick={(e) => {
											e.preventDefault();
											this.props.updateSearchKeywords("", "");
											this.props.history.push(
												internal_uri.formatted_frontend_jobs_page_path(),
											);
										}}>
										<h5>{t("all_jobs")}</h5>
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_frontend_employer_landing_page_path()}
										data-event-category="004_nav_employer">
										<h5>{t("employer")}</h5>
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_frontend_referrer_landing_page_path()}
										data-event-category="003_nav_referrer">
										<h5>{t("referrer")}</h5>
									</NavLink>
								</li>
								<li>
									<a
										href="https://column.meet.jobs"
										data-event-category="005_nav_column">
										<h5>{t("column")}</h5>
									</a>
								</li>
								{!login ? (
									<li className="with-btn with-border-top">
										<NavLink
											exact
											to={internal_uri.formatted_frontend_log_in_page_path()}
											data-event-category="031_nav_log_in">
											<h5>{t("log_in")}</h5>
										</NavLink>
										<NavLink
											exact
											to={internal_uri.formatted_frontend_sign_up_page_path()}
											className="btn btn-larger btn-flat btn-hollow"
											data-event-category="032_nav_sign_up">
											<h5>{t("sign_up")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
							</ul>
						</div>
					</div>

					<div className="mobile-sub-menu-wrapper">
						<div className="container">
							<div className="mobile-sub-menu">
								<ul>
									<li
										className="with-back-arrow with-border-bottom"
										onClick={(e) => toggleMobileSubMenu(e)}>
										<a href="/#back" onClick={(e) => e.preventDefault()}>
											<h5>{t("back")}</h5>
										</a>
									</li>
									{employer ? (
										<li className="with-avatar with-employer-avatar with-border-bottom">
											<NavLink
												to={internal_uri.formatted_dashboard_index_page_path()}
												data-event-category="011_employer_dashboard">
												<div className="avatar">
													<div
														className="image-wrapper"
														style={{
															backgroundImage: `url('${active_company.formatted_logo_toolbar_url()}')`,
														}}
													/>
												</div>
												<h5>{active_company.name}</h5>
											</NavLink>
										</li>
									) : (
										""
									)}
									{DashboardMenuInner.map(({ name, path, category }, idx) => (
										<li
											key={idx}
											className={`${
												!referral && name === "my_referrals" ? "with-btn" : ""
											}`}>
											<NavLink
												exact
												to={internal_uri[path]()}
												data-event-category={category.header}>
												<h5>{t(name)}</h5>
											</NavLink>
											{!referral && name === "my_referrals" ? (
												<NavLink
													exact
													to={internal_uri.formatted_frontend_referrer_landing_page_path()}
													className="btn btn-larger btn-flat btn-fill">
													<h5>{t("get_reward")}</h5>
												</NavLink>
											) : null}
										</li>
									))}
									<li>
										<a
											onClick={(e) => signOut(e)}
											href="/#logout"
											data-event-category="016_log_out">
											<h5>{t("log_out")}</h5>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* remind to upload resume */}
				{this.state.isRemindUploadResumeBannerOpen && (
					<RemindUpLoadResumeBanner
						{...this.props}
						close={() =>
							this.setState({ isRemindUploadResumeBannerOpen: false })
						}
						isRemindUploadResumeBannerOpen={
							this.state.isRemindUploadResumeBannerOpen
						}
					/>
				)}
				{/* <RemindUpLoadResumeBanner
					{...this.props}
					isRemindUploadResumeBannerOpen={true}
					close={() => this.setState({ isRemindUploadResumeBannerOpen: false })}
				/> */}
			</header>
		);
	}
}

export default withRouter(
	connect(null, { signOutUser })(withNamespaces(["general"])(Header)),
);
