import {updateCoverAdDismissModal} from "./actions";

const constants = {

    // frontend
    UPDATE_APPLY_MODAL: "UPDATE_APPLY_MODAL",
    UPDATE_INVITE_TO_APPLY_MODAL: "UPDATE_INVITE_TO_APPLY_MODAL",

    // dashboard
    UPDATE_EMPLOYER_MESSAGE_MODAL: "UPDATE_EMPLOYER_MESSAGE_MODAL",
    UPDATE_CREATE_EMPLOYER_MODAL: "UPDATE_CREATE_EMPLOYER_MODAL",
    UPDATE_ADD_MEMBER_MODAL: "UPDATE_ADD_MEMBER_MODAL",
    UPDATE_EDIT_MEMBER_MODAL: "UPDATE_EDIT_MEMBER_MODAL",
    UPDATE_EDIT_INVITED_MEMBER_MODAL: "UPDATE_EDIT_INVITED_MEMBER_MODAL",
    UPDATE_CONTACT_MEET_JOBS_MODAL: "UPDATE_CONTACT_MEET_JOBS_MODAL",
    UPDATE_WELCOME_EMPLOYER_MODAL: "UPDATE_WELCOME_EMPLOYER_MODAL",
    UPDATE_EMPLOYER_PROFILE_PENDING_DEGREE_OF_COMPLETION: "UPDATE_EMPLOYER_PROFILE_PENDING_DEGREE_OF_COMPLETION",

    // userpanel
    UPDATE_CANDIDATE_MESSAGE_MODAL: "UPDATE_CANDIDATE_MESSAGE_MODAL",
    UPDATE_REFERRAL_MESSAGE_MODAL: "UPDATE_REFERRAL_MESSAGE_MODAL",
    UPDATE_ADD_REFERENCE_NOTES_MODAL: "UPDATE_ADD_REFERENCE_NOTES_MODAL",
    UPDATE_READ_REFERENCE_NOTES_MODAL: "UPDATE_READ_REFERENCE_NOTES_MODAL",
    UPDATE_CLAIM_REFERRAL_REWARD_MODAL: "UPDATE_CLAIM_REFERRAL_REWARD_MODAL",
    UPDATE_MY_REFERRAL_LINK_MODAL: "UPDATE_MY_REFERRAL_LINK_MODAL",

    // general
    UPDATE_SEARCH_BAR: "UPDATE_SEARCH_BAR",
    UPDATE_KEEP_SEARCH_BAR: "UPDATE_KEEP_SEARCH_BAR",
    UPDATE_SEARCH_KEYWORDS: "UPDATE_SEARCH_KEYWORDS",
    UPDATE_DASHBOARD_SIDEBAR_COLLAPSED: "UPDATE_DASHBOARD_SIDEBAR_COLLAPSED",

    UPDATE_ENTRANCE_FORM_MODAL: "UPDATE_ENTRANCE_FORM_MODAL",
    UPDATE_MOBILE_MENU: "UPDATE_MOBILE_MENU",
    UPDATE_SUB_MENU: "UPDATE_SUB_MENU",
    CLOSE_ALL: "CLOSE_ALL",
    CLOSE_HEADER: "CLOSE_HEADER",

    ADD_SUBSCRIBE: "ADD_SUBSCRIBE",
    REMOVE_SUBSCRIBE: "REMOVE_SUBSCRIBE",
    // UPDATE_LOADING: "UPDATE_LOADING",
    UPDATE_LOG_OUT_MODAL: "UPDATE_LOG_OUT_MODAL",
    UPDATE_CONNECTION_OFF_MODAL: "UPDATE_CONNECTION_OFF_MODAL",
    ADD_FLASH_MESSAGE: "ADD_FLASH_MESSAGE",
    REMOVE_FLASH_MESSAGE: "REMOVE_FLASH_MESSAGE",
    OPEN_FLASH_MESSAGE: "OPEN_FLASH_MESSAGE",

    UPDATE_AD_CORNER_MODAL: "UPDATE_AD_CORNER_MODAL",
    UPDATE_AD_COVER_MODAL: "UPDATE_AD_COVER_MODAL",

    // current user
    UPDATE_COOKIE_POLICY_CORNER_MODAL: "UPDATE_COOKIE_POLICY_CORNER_MODAL",
    UPDATE_CURRENT_USER_COMPANIES: "UPDATE_CURRENT_USER_COMPANIES",
    UPDATE_CURRENT_USER_ACTIVE_COMPANY: "UPDATE_CURRENT_USER_ACTIVE_COMPANY",
    UPDATE_CURRENT_USER_EMPLOYER_ROLES: "UPDATE_CURRENT_USER_EMPLOYER_ROLES",
    UPDATE_CURRENT_USER_REFERRAL_CODES: "UPDATE_CURRENT_USER_REFERRAL_CODES",
    ADD_CURRENT_USER_REFERRAL_CODE: "ADD_CURRENT_USER_REFERRAL_CODE",
    DELETE_CURRENT_USER_REFERRAL_CODE: "DELETE_CURRENT_USER_REFERRAL_CODE",
    UPDATE_CURRENT_USER_IS_REFERRAL_USER: "UPDATE_CURRENT_USER_IS_REFERRAL_USER",
    UPDATE_CURRENT_USER_IS_EMPLOYER_USER: "UPDATE_CURRENT_USER_IS_EMPLOYER_USER",

    // events
    UPDATE_ADS: "UPDATE_ADS",
    UPDATE_ADS_COVER: "UPDATE_ADS_COVER",

    // utm
    UPDATE_UTM: "UPDATE_UTM",

    // cover ad
    UPDATE_COVER_ADS_MODAL_DISMISS : "UPDATE_COVER_ADS_MODAL_DISMISS",
};

export default constants

