import React, { useState, useRef, useEffect } from "react";
import Config from "../../models/config.js";
import ApiHeaders from "../../models/api_headers";
import axios from "axios";
import Employer from "../../models/employer.js";
import Select from "react-select";
import Metas from "../../models/metas";
import Slider from "react-slick/lib";
import { withNamespaces } from "react-i18next";
import {
	get_options,
	get_value_from_query_string,
	contain_not_only_whitespace,
	page_smothly_scroll_to,
	smoothly_scroll_to,
} from "../../../assets/js/functions";
import { isSlug } from "../../../assets/js/validators";
import plan_hire from "../../../assets/images/i011-plan_02.svg";
import plan_hire_plus from "../../../assets/images/i012-plan_03.svg";
import Textarea from "react-textarea-autosize";
import icon_download from "../../../assets/images/icon/icon_24_download_B100.svg";
import welcome_banner_img from "../../../assets/images/i036-splash1.svg";
import welcome_banner_img_hp_en from "../../../assets/images/i037-splash2.svg";
import welcome_banner_img_ft_en from "../../../assets/images/i038-splash3.svg";
import welcome_banner_img_hp_zh from "../../../assets/images/i040-splash2_TC.svg";
import welcome_banner_img_ft_zh from "../../../assets/images/i041-splash3_TC.svg";
import i18n from "../../../i18n";
import InternalUri from "../../models/internal_uri";
import ThreeBasicRules from "./ThreeBasicRules";
import modal_pop_up_assure from "../../../assets/images/i049-pop-ups-assure.svg";
import { CSSTransitionGroup } from "react-transition-group";
import DeviceDetector from "../../models/device_detector";
import downloadjs from "downloadjs";

const config = new Config();
const headers = new ApiHeaders();

// const CreateEmployerFormf = (props) => {
// 	const slider_settings = {
// 		dots: false,
// 		arrows: false,
// 		infinite: false,
// 		slidesToShow: 3,
// 		initialSlide: 1,
// 		responsive: [
// 			{
// 				breakpoint: 991,
// 				settings: {
// 					speed: 500,
// 					dots: false,
// 					arrows: false,
// 					infinite: false,
// 					centerMode: true,
// 					centerPadding: "185px",
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 				},
// 			},
// 			{
// 				breakpoint: 720,
// 				settings: {
// 					speed: 500,
// 					dots: false,
// 					arrows: false,
// 					infinite: false,
// 					centerMode: true,
// 					centerPadding: "160px",
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 				},
// 			},
// 			{
// 				breakpoint: 600,
// 				settings: {
// 					speed: 500,
// 					dots: false,
// 					arrows: false,
// 					infinite: false,
// 					centerMode: true,
// 					centerPadding: "105px",
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 				},
// 			},
// 			{
// 				breakpoint: 480,
// 				settings: {
// 					speed: 500,
// 					dots: false,
// 					arrows: false,
// 					infinite: false,
// 					centerMode: true,
// 					centerPadding: "36px",
// 					slidesToShow: 1,
// 					slidesToScroll: 1,
// 				},
// 			},
// 		],
// 	};
// 	const handleSelectChange = (property_name, selected_option) => {
// 		if (selected_option) {
// 			setMain((prev_state) => ({
// 				form_data: {
// 					...prev_state.form_data,
// 					[property_name]: selected_option.value,
// 				},
// 			}));
// 		}
// 	};
// 	const handleInputChange = (e, property_name) => {
// 		e.preventDefault();
// 		let value = e.target.value;

// 		if (property_name === "slug") value = value.toLowerCase();

// 		setMain((prev_state) => {
// 			// check form error
// 			let error_messages = { ...prev_state.error_messages };
// 			const { t } = props;
// 			switch (property_name) {
// 				case "name":
// 					let name = [];
// 					if (value.length <= 0) name.push(`${t("general:required")}`);
// 					error_messages = {
// 						...error_messages,
// 						name,
// 					};
// 					break;
// 				case "contact_name":
// 					let contact_name = [];
// 					if (value.length <= 0) contact_name.push(`${t("general:required")}`);
// 					error_messages = {
// 						...error_messages,
// 						"contact.name": contact_name,
// 					};
// 					break;
// 				case "contact_phone_number":
// 					let contact_phone_number = [];
// 					if (value.length <= 0)
// 						contact_phone_number.push(`${t("general:required")}`);
// 					error_messages = {
// 						...error_messages,
// 						"contact.mobile": contact_phone_number,
// 					};
// 					break;
// 				case "city":
// 					let city = [];
// 					if (value.length <= 0) city.push(`${t("general:required")}`);
// 					error_messages = {
// 						...error_messages,
// 						"addresses.handwriting_city": city,
// 					};
// 					break;
// 				case "slug":
// 					let slug = [];
// 					if (value.length <= 0) slug.push(`${t("general:required")}`);
// 					if (!isSlug(value)) slug.push(`${t("general:invalid_slug_format")}`);
// 					error_messages = {
// 						...error_messages,
// 						slug,
// 					};
// 					break;
// 				default:
// 					error_messages = {
// 						...error_messages,
// 						[property_name]: [],
// 					};
// 					break;
// 			}

// 			return {
// 				...prev_state,
// 				form_data: {
// 					...prev_state.form_data,
// 					[property_name]: value,
// 				},
// 				error_messages,
// 			};
// 		});
// 	};
// 	const handleRadioChange = (e, property_name, value) => {
// 		setMain((prev_state) => ({
// 			...prev_state,
// 			form_data: {
// 				...prev_state.form_data,
// 				[property_name]: value,
// 			},
// 		}));
// 	};
// 	const handleActiveStep = (e, new_step) => {
// 		e.preventDefault();

// 		const { form_data } = main;

// 		// next 前的檢查
// 		let has_errors = false;
// 		let error_messages = { ...this.state.error_messages };
// 		if (main.steps.active === 1) {
// 			const { t } = props;
// 			let name = [],
// 				contact_name = [],
// 				contact_phone_number = [],
// 				city = [],
// 				slug = [];
// 			if (form_data.name.length <= 0) {
// 				name.push(`${t("general:required")}`);
// 				has_errors = true;
// 			}
// 			if (form_data.contact_name.length <= 0) {
// 				contact_name.push(`${t("general:required")}`);
// 				has_errors = true;
// 			}
// 			if (form_data.contact_phone_number.length <= 0) {
// 				contact_phone_number.push(`${t("general:required")}`);
// 				has_errors = true;
// 			}
// 			if (form_data.city.length <= 0) {
// 				city.push(`${t("general:required")}`);
// 				has_errors = true;
// 			}
// 			if (form_data.slug.length <= 0) {
// 				slug.push(`${t("general:required")}`);
// 				has_errors = true;
// 			}
// 			if (!isSlug(form_data.slug)) {
// 				slug.push(`${t("general:invalid_slug_format")}`);
// 				has_errors = true;
// 			}
// 			error_messages = {
// 				...error_messages,
// 				name,
// 				"contact.name": contact_name,
// 				"contact.mobile": contact_phone_number,
// 				"addresses.handwriting_city": city,
// 				slug,
// 			};
// 		}

// 		if (has_errors) {
// 			setMain((currentState) => ({ ...currentState, error_messages }));
// 		} else {
// 			setMain((prev_state) => ({
// 				...prev_state,
// 				steps: {
// 					...prev_state.steps,
// 					active: new_step,
// 					[new_step]: {
// 						...prev_state.steps[new_step],
// 						visited: true,
// 					},
// 				},
// 			}));
// 		}

// 		if (props.site_state.create_employer_modal) {
// 			smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
// 		} else {
// 			page_smothly_scroll_to(0, 300);
// 		}
// 	};
// 	const handleAgreementChange = (e) => {
// 		e.preventDefault();
// 		setMain((prev_state) => ({
// 			...prev_state,
// 			form_data: {
// 				...prev_state.form_data,
// 				agreement_checked: !prev_state.form_data.agreement_checked,
// 			},
// 		}));
// 	};
// 	const cancelTheAction = (e) => {
// 		e.preventDefault();
// 		setMain((prev_state) => ({
// 			...prev_state,
// 			message_modal: {
// 				open: false,
// 			},
// 		}));
// 	};
// 	const createEmployerWithoutContract = (e) => {
// 		e.preventDefault();
// 		setMain((prev_state) => ({
// 			...prev_state,
// 			message_modal: {
// 				open: true,
// 			},
// 		}));
// 	};

// 	const submitForm = (e, way = null) => {
// 		e.preventDefault();
// 		const { form_data, metas, sending } = main;

// 		if (!sending) {
// 			setMain((currentState) => ({ ...currentState, sending: true }));
// 			let formData = new FormData();
// 			formData.append("employer[name]", form_data.name);
// 			formData.append(
// 				"employer[contact_attributes][name]",
// 				form_data.contact_name,
// 			);
// 			if (contain_not_only_whitespace(form_data.contact_phone_number))
// 				formData.append(
// 					"employer[contact_attributes][mobile]",
// 					`${form_data.phone_codes_selected_value}${form_data.contact_phone_number}`,
// 				);
// 			formData.append(
// 				"employer[addresses_attributes][0][handwriting_country]",
// 				metas.countries.value(form_data.countries_selected_value),
// 			);
// 			formData.append(
// 				"employer[addresses_attributes][0][handwriting_city]",
// 				form_data.city,
// 			);
// 			formData.append("employer[industry]", "other");
// 			formData.append("employer[company_size]", "0");
// 			formData.append("employer[slug]", form_data.slug);
// 			formData.append(
// 				"employer[referrer_name]",
// 				form_data.referrer_information,
// 			);
// 			formData.append("employer[choose_plan]", form_data.plan_checked);
// 			formData.append(
// 				"employer[setting_attributes][locale]",
// 				navigator.language,
// 			);
// 			if (way === "green_channel")
// 				formData.append("contract[rule_type]", "three_basic");
// 			else formData.append("contract[rule_type]", "complete");

// 			let options = {
// 				method: "POST",
// 				url: config.api_url("/employers"),
// 				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
// 				data: formData,
// 				json: true,
// 			};
// 			let active_company = {},
// 				companies = [];
// 			axios(options)
// 				.then((response) => {
// 					headers.updateItemsToLocalStorage(response.headers);
// 					props.updateConnectionOffModal(false);

// 					if (isMounted.current) {
// 						const raw_company = response.data;
// 						active_company = new Employer(raw_company);

// 						options = {
// 							method: "GET",
// 							url: config.api_url(
// 								`/users/${props.reduxTokenAuth.currentUser.attributes.id}?include=employer_roles`,
// 							),
// 							headers: headers.getItemsFromLocalStorage(),
// 							json: true,
// 						};
// 						return axios(options);
// 					}
// 				})
// 				.then((response) => {
// 					headers.updateItemsToLocalStorage(response.headers);

// 					if (isMounted.current) {
// 						let promises_employers = [],
// 							employer_roles = {};
// 						const raw_employer_roles = response.data.employer_roles;
// 						for (let i = 0; i < raw_employer_roles.length; i++) {
// 							options = {
// 								method: "GET",
// 								url: config.api_url(
// 									`/employers/${raw_employer_roles[i].employer_id}`,
// 								),
// 								headers: headers.getItemsFromLocalStorage(),
// 								json: true,
// 							};
// 							promises_employers.push(axios(options));
// 							employer_roles[raw_employer_roles[i].employer_id] =
// 								raw_employer_roles[i];
// 						}
// 						props.updateCurrentUserEmployerRoles(employer_roles);

// 						return axios.all(promises_employers);
// 					}
// 				})
// 				.then((results) => {
// 					for (let i = 0; i < results.length; i++) {
// 						headers.updateItemsToLocalStorage(results[i].headers);
// 					}

// 					if (isMounted.current) {
// 						for (let i = 0; i < results.length; i++) {
// 							const company = new Employer(results[i].data);
// 							companies.push(company);
// 						}

// 						props.updateCurrentUserCompanies(companies);
// 						props.updateCurrentUserActiveCompany({ ...active_company });
// 						props.updateCurrentUserIsEmployerUser(companies.length > 0);

// 						setMain(
// 							{
// 								sending: false,
// 								error_messages: {},
// 							},
// 							() => {
// 								props.updateCreateEmployerModal(false);

// 								const internal_uri = new InternalUri();
// 								props.history.push(
// 									internal_uri.formatted_dashboard_employer_profile_page_path(),
// 								);

// 								if (way === "green_channel") {
// 									props.updateWelcomeEmployerModal(
// 										true,
// 										i18n.language === "zh-TW"
// 											? welcome_banner_img_hp_zh
// 											: welcome_banner_img_hp_en,
// 										"we_have_placed_you_in_high_priority",
// 										"we_will_reach_you_via_the_information_you_have_provided_",
// 										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
// 										null,
// 									);
// 								} else if (form_data.plan_checked === "post_only") {
// 									props.updateWelcomeEmployerModal(
// 										true,
// 										i18n.language === "zh-TW"
// 											? welcome_banner_img_ft_zh
// 											: welcome_banner_img_ft_en,
// 										"you_are_all_set",
// 										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
// 										null,
// 										"we_are_pleased_to_provide_you__60_days_free_trial__",
// 									);
// 								} else {
// 									props.updateWelcomeEmployerModal(
// 										true,
// 										welcome_banner_img,
// 										"you_are_all_set",
// 										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
// 										null,
// 										null,
// 									);
// 								}
// 							},
// 						);
// 					}
// 				})
// 				.catch((error) => {
// 					if (error.response) {
// 						headers.updateItemsToLocalStorage(error.response.headers);
// 						if (error.response.status === 401) window.location.reload();
// 					}

// 					if (isMounted.current) {
// 						if (typeof error.response === "undefined") {
// 							window.location.reload();
// 							props.updateConnectionOffModal(true);
// 						}

// 						setMain((prev_state) => {
// 							if (
// 								error.response &&
// 								error.response.data &&
// 								error.response.data.status === "error"
// 							) {
// 								// 將 errors.contact 分別塞進 errors.contact.name & errors.contact.mobile
// 								if (
// 									error.response.data.errors &&
// 									error.response.data.errors.contact
// 								) {
// 									if (error.response.data.errors["contact.name"])
// 										error.response.data.errors["contact.name"].concat(
// 											error.response.data.errors.contact,
// 										);
// 									else
// 										error.response.data.errors["contact.name"] =
// 											error.response.data.errors.contact;

// 									if (error.response.data.errors["contact.mobile"])
// 										error.response.data.errors["contact.mobile"].concat(
// 											error.response.data.errors.contact,
// 										);
// 									else
// 										error.response.data.errors["contact.mobile"] =
// 											error.response.data.errors.contact;

// 									error.response.data.errors.contact = [];
// 								}

// 								// 將 errors.addressess 分別塞進 errors.addressess.handwriting_country & errors.addressess.handwriting_city
// 								if (
// 									error.response.data.errors &&
// 									error.response.data.errors.addresses
// 								) {
// 									if (
// 										error.response.data.errors["addresses.handwriting_country"]
// 									)
// 										error.response.data.errors[
// 											"addresses.handwriting_country"
// 										].concat(error.response.data.errors.addresses);
// 									else
// 										error.response.data.errors[
// 											"addresses.handwriting_country"
// 										] = error.response.data.errors.addresses;

// 									if (error.response.data.errors["addresses.handwriting_city"])
// 										error.response.data.errors[
// 											"addresses.handwriting_city"
// 										].concat(error.response.data.errors.addresses);
// 									else
// 										error.response.data.errors["addresses.handwriting_city"] =
// 											error.response.data.errors.addresses;

// 									error.response.data.errors.addresses = [];
// 								}
// 							}
// 							return {
// 								...prev_state,
// 								message_modal: {
// 									open: false,
// 								},
// 								error_messages:
// 									error.response &&
// 									error.response.data &&
// 									error.response.data.status === "error"
// 										? error.response.data.errors
// 										: {
// 												full_message:
// 													"There was an error. Please try again later.",
// 										  },
// 								steps: {
// 									...prev_state.steps,
// 									active: 1,
// 								},
// 								sending: false,
// 							};
// 						});

// 						if (props.site_state.create_employer_modal) {
// 							smoothly_scroll_to(
// 								document.querySelector(".modal-wrapper"),
// 								0,
// 								200,
// 							);
// 						} else {
// 							page_smothly_scroll_to(0, 300);
// 						}
// 					}
// 				});
// 		}
// 	};
// 	const [main, setMain] = useState({
// 		steps: {
// 			active: 1,
// 			1: { visited: true },
// 			2: { visited: false },
// 			3: { visited: false },
// 		},
// 		form_data: {},
// 		options: {},
// 		metas: {},
// 		message_modal: {
// 			open: false,
// 		},
// 		error_messages: {},
// 		loading: true,
// 		sending: false,
// 	});

// 	const isMounted = useRef(false);
// 	useEffect(() => {
// 		if (!isMounted.current) {
// 			isMounted.current = true;
// 			if (props.reduxTokenAuth.currentUser.isSignedIn) {
// 				// console.log('您尚未登入');
// 				const internal_uri = new InternalUri();
// 				const { location } = props;
// 				props.history.push(
// 					`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
// 						location.pathname
// 					}${location.search}`,
// 				);
// 				props.updateCreateEmployerModal(false);
// 			}

// 			let plan_checked = "custom_referral";
// 			let preselected = false;
// 			const query = decodeURI(props.location.search);
// 			if (query) {
// 				let selected_plan = get_value_from_query_string("selected_plan", query);
// 				if (
// 					selected_plan === "custom_referral" ||
// 					selected_plan === "social_referral"
// 				) {
// 					plan_checked = selected_plan;
// 				}

// 				preselected =
// 					get_value_from_query_string("preselected", query) === null
// 						? false
// 						: true;
// 			}
// 			setMain((currentState) => ({
// 				...currentState,
// 				preselected,
// 				form_data: {
// 					name: "",
// 					slug: "",
// 					contact_name: "",
// 					phone_codes_selected_value: "",
// 					contact_phone_number: "",
// 					countries_selected_value: "TW",
// 					city: "",
// 					referrer_information: "",
// 					plan_checked,
// 					agreement_checked: false,
// 					card_data: {},
// 				},
// 			}));
// 			let options = {
// 				method: "GET",
// 				url: config.api_url("/countries"),
// 				headers: headers.getItemsFromLocalStorage(),
// 				json: true,
// 			};
// 			axios(options)
// 				.then((response) => {
// 					headers.updateItemsToLocalStorage(response.headers);
// 					props.updateConnectionOffModal(false);

// 					if (isMounted.current) {
// 						const raw_data = response.data;
// 						setMain((prev_state) => ({
// 							...prev_state,
// 							form_data: {
// 								...prev_state.form_data,
// 								phone_codes_selected_value: "886",
// 								countries_selected_value: "TW",
// 							},
// 							options: {
// 								phone_codes: get_options("phone_codes", raw_data),
// 								countries: get_options("countries", raw_data),
// 							},
// 							metas: {
// 								countries: new Metas("countries", raw_data),
// 							},
// 							error_messages: {},
// 						}));
// 					}
// 				})
// 				.catch((error) => {
// 					if (error.response) {
// 						headers.updateItemsToLocalStorage(error.response.headers);
// 						if (error.response.status === 401) window.location.reload();
// 					}

// 					if (isMounted.current) {
// 						if (typeof error.response === "undefined") {
// 							window.location.reload();
// 							props.updateConnectionOffModal(true);
// 						}

// 						setMain((prev_state) => ({
// 							...prev_state,
// 							error_messages:
// 								error.response &&
// 								error.response.data &&
// 								error.response.data.status === "error"
// 									? error.response.data.errors
// 									: {
// 											full_message:
// 												"There was an error. Please try again later.",
// 									  },
// 						}));

// 						if (props.site_state.create_employer_modal) {
// 							smoothly_scroll_to(
// 								document.querySelector(".modal-wrapper"),
// 								0,
// 								200,
// 							);
// 						} else {
// 							page_smothly_scroll_to(0, 300);
// 						}
// 					}
// 				});
// 		} else {
// 		}
// 		return () => {
// 			isMounted.current = false;
// 		};
// 	}, []);
// 	// UNSAFE_componentWillReceiveProps
// 	let preProps = props;
// 	useEffect(() => {
// 		if (
// 			preProps.reduxTokenAuth.currentUser.isSignedIn !==
// 			props.reduxTokenAuth.currentUser.isSignedIn
// 		) {
// 			if (!props.reduxTokenAuth.currentUser.isSignedIn) {
// 				const internal_uri = new InternalUri();
// 				const { location } = props;
// 				props.history.push(
// 					`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
// 						location.pathname
// 					}${location.search}`,
// 				);
// 				props.updateCreateEmployerModal(false);
// 			}
// 		}
// 	}, [props]);
// };
class CreateEmployerForm extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleRadioChange = this.handleRadioChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleActiveStep = this.handleActiveStep.bind(this);
		this.handleAgreementChange = this.handleAgreementChange.bind(this);
		this.cancelTheAction = this.cancelTheAction.bind(this);
		this.createEmployerWithoutContract = this.createEmployerWithoutContract.bind(
			this,
		);
		this.submitForm = this.submitForm.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.state = {
			steps: {
				active: 1,
				1: { visited: true },
				2: { visited: false },
				3: { visited: false },
			},
			form_data: {},
			options: {},
			metas: {},
			message_modal: {
				open: false,
			},
			error_messages: {},
			loading: true,
			sending: false,
		};
		this.slider_settings = {
			dots: false,
			arrows: false,
			infinite: false,
			slidesToShow: 3,
			initialSlide: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "185px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 720,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "160px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 600,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "105px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						speed: 500,
						dots: false,
						arrows: false,
						infinite: false,
						centerMode: true,
						centerPadding: "36px",
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
	}

	componentDidMount() {
		this._isMounted = true;

		if (!this.props.reduxTokenAuth.currentUser.isSignedIn) {
			// console.log('您尚未登入');
			const internal_uri = new InternalUri();
			const { location } = this.props;
			this.props.history.push(
				`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
					location.pathname
				}${location.search}`,
			);
			this.props.updateCreateEmployerModal(false);
		}

		let plan_checked = "custom_referral";
		let preselected = false;
		const query = decodeURI(this.props.location.search);
		if (query) {
			let selected_plan = get_value_from_query_string("selected_plan", query);
			if (
				selected_plan === "custom_referral" ||
				selected_plan === "social_referral"
			) {
				plan_checked = selected_plan;
			}

			preselected =
				get_value_from_query_string("preselected", query) === null
					? false
					: true;
		}

		this.setState({
			preselected,
			form_data: {
				name: "",
				slug: "",
				contact_name: "",
				phone_codes_selected_value: "",
				contact_phone_number: "",
				countries_selected_value: "TW",
				city: "",
				referrer_information: "",
				plan_checked,
				agreement_checked: false,
				card_data: {},
			},
		});
		let options = {
			method: "GET",
			url: config.api_url("/countries"),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_data = response.data;
					this.setState((prev_state) => ({
						...prev_state,
						form_data: {
							...prev_state.form_data,
							phone_codes_selected_value: "886",
							countries_selected_value: "TW",
						},
						options: {
							phone_codes: get_options("phone_codes", raw_data),
							countries: get_options("countries", raw_data),
						},
						metas: {
							countries: new Metas("countries", raw_data),
						},
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState(
						(prev_state) => ({
							...prev_state,
							error_messages:
								error.response &&
								error.response.data &&
								error.response.data.status === "error"
									? error.response.data.errors
									: {
											full_message:
												"There was an error. Please try again later.",
									  },
						}),
						() => {
							if (this.props.site_state.create_employer_modal) {
								smoothly_scroll_to(
									document.querySelector(".modal-wrapper"),
									0,
									200,
								);
							} else {
								page_smothly_scroll_to(0, 300);
							}
						},
					);
				}
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (
			this.props.reduxTokenAuth.currentUser.isSignedIn !==
			next_props.reduxTokenAuth.currentUser.isSignedIn
		) {
			if (!next_props.reduxTokenAuth.currentUser.isSignedIn) {
				const internal_uri = new InternalUri();
				const { location } = this.props;
				this.props.history.push(
					`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
						location.pathname
					}${location.search}`,
				);
				this.props.updateCreateEmployerModal(false);
			}
		}
	}

	handleSelectChange = (property_name, selected_option) => {
		if (selected_option) {
			this.setState((prev_state) => ({
				form_data: {
					...prev_state.form_data,
					[property_name]: selected_option.value,
				},
			}));
		}
	};

	handleInputChange = (e, property_name) => {
		e.preventDefault();
		let value = e.target.value;

		if (property_name === "slug") value = value.toLowerCase();

		this.setState((prev_state) => {
			// check form error
			let error_messages = { ...prev_state.error_messages };
			const { t } = this.props;
			switch (property_name) {
				case "name":
					let name = [];
					if (value.length <= 0) name.push(`${t("general:required")}`);
					error_messages = {
						...error_messages,
						name,
					};
					break;
				case "contact_name":
					let contact_name = [];
					if (value.length <= 0) contact_name.push(`${t("general:required")}`);
					error_messages = {
						...error_messages,
						"contact.name": contact_name,
					};
					break;
				case "contact_phone_number":
					let contact_phone_number = [];
					if (value.length <= 0)
						contact_phone_number.push(`${t("general:required")}`);
					error_messages = {
						...error_messages,
						"contact.mobile": contact_phone_number,
					};
					break;
				case "city":
					let city = [];
					if (value.length <= 0) city.push(`${t("general:required")}`);
					error_messages = {
						...error_messages,
						"addresses.handwriting_city": city,
					};
					break;
				case "slug":
					let slug = [];
					if (value.length <= 0) slug.push(`${t("general:required")}`);
					if (!isSlug(value)) slug.push(`${t("general:invalid_slug_format")}`);
					error_messages = {
						...error_messages,
						slug,
					};
					break;
				default:
					error_messages = {
						...error_messages,
						[property_name]: [],
					};
					break;
			}

			return {
				...prev_state,
				form_data: {
					...prev_state.form_data,
					[property_name]: value,
				},
				error_messages,
			};
		});
	};

	handleRadioChange(e, property_name, value) {
		this.setState((prev_state) => ({
			...prev_state,
			form_data: {
				...prev_state.form_data,
				[property_name]: value,
			},
		}));
	}

	handleActiveStep(e, new_step) {
		e.preventDefault();

		const { form_data } = this.state;

		// next 前的檢查
		let has_errors = false;
		let error_messages = { ...this.state.error_messages };
		if (this.state.steps.active === 1) {
			const { t } = this.props;
			let name = [],
				contact_name = [],
				contact_phone_number = [],
				city = [],
				slug = [];
			if (form_data.name.length <= 0) {
				name.push(`${t("general:required")}`);
				has_errors = true;
			}
			if (form_data.contact_name.length <= 0) {
				contact_name.push(`${t("general:required")}`);
				has_errors = true;
			}
			if (form_data.contact_phone_number.length <= 0) {
				contact_phone_number.push(`${t("general:required")}`);
				has_errors = true;
			}
			if (form_data.city.length <= 0) {
				city.push(`${t("general:required")}`);
				has_errors = true;
			}
			if (form_data.slug.length <= 0) {
				slug.push(`${t("general:required")}`);
				has_errors = true;
			}
			if (!isSlug(form_data.slug)) {
				slug.push(`${t("general:invalid_slug_format")}`);
				has_errors = true;
			}
			error_messages = {
				...error_messages,
				name,
				"contact.name": contact_name,
				"contact.mobile": contact_phone_number,
				"addresses.handwriting_city": city,
				slug,
			};
		}

		if (has_errors) {
			this.setState({ error_messages });
		} else {
			this.setState((prev_state) => ({
				...prev_state,
				steps: {
					...prev_state.steps,
					active: new_step,
					[new_step]: {
						...prev_state.steps[new_step],
						visited: true,
					},
				},
			}));
		}

		if (this.props.site_state.create_employer_modal) {
			smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
		} else {
			page_smothly_scroll_to(0, 300);
		}
	}

	handleAgreementChange = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			form_data: {
				...prev_state.form_data,
				agreement_checked: !prev_state.form_data.agreement_checked,
			},
		}));
	};

	cancelTheAction = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			message_modal: {
				open: false,
			},
		}));
	};

	createEmployerWithoutContract = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			message_modal: {
				open: true,
			},
		}));
	};

	submitForm = (e, way = null) => {
		e.preventDefault();
		const { form_data, metas, sending } = this.state;

		if (!sending) {
			this.setState({ sending: true });
			let formData = new FormData();
			formData.append("employer[name]", form_data.name);
			formData.append(
				"employer[contact_attributes][name]",
				form_data.contact_name,
			);
			if (contain_not_only_whitespace(form_data.contact_phone_number))
				formData.append(
					"employer[contact_attributes][mobile]",
					`${form_data.phone_codes_selected_value}${form_data.contact_phone_number}`,
				);
			formData.append(
				"employer[addresses_attributes][0][handwriting_country]",
				metas.countries.value(form_data.countries_selected_value),
			);
			formData.append(
				"employer[addresses_attributes][0][handwriting_city]",
				form_data.city,
			);
			formData.append("employer[industry]", "other");
			formData.append("employer[company_size]", "0");
			formData.append("employer[slug]", form_data.slug);
			formData.append(
				"employer[referrer_name]",
				form_data.referrer_information,
			);
			formData.append("employer[choose_plan]", form_data.plan_checked);
			formData.append(
				"employer[setting_attributes][locale]",
				navigator.language,
			);
			if (way === "green_channel")
				formData.append("contract[rule_type]", "three_basic");
			else formData.append("contract[rule_type]", "complete");

			let options = {
				method: "POST",
				url: config.api_url("/employers"),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			let active_company = {},
				companies = [];
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					if (this._isMounted) {
						const raw_company = response.data;
						active_company = new Employer(raw_company);

						options = {
							method: "GET",
							url: config.api_url(
								`/users/${this.props.reduxTokenAuth.currentUser.attributes.id}?include=employer_roles`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};
						return axios(options);
					}
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);

					if (this._isMounted) {
						let promises_employers = [],
							employer_roles = {};
						const raw_employer_roles = response.data.employer_roles;
						for (let i = 0; i < raw_employer_roles.length; i++) {
							options = {
								method: "GET",
								url: config.api_url(
									`/employers/${raw_employer_roles[i].employer_id}`,
								),
								headers: headers.getItemsFromLocalStorage(),
								json: true,
							};
							promises_employers.push(axios(options));
							employer_roles[raw_employer_roles[i].employer_id] =
								raw_employer_roles[i];
						}
						this.props.updateCurrentUserEmployerRoles(employer_roles);

						return axios.all(promises_employers);
					}
				})
				.then((results) => {
					for (let i = 0; i < results.length; i++) {
						headers.updateItemsToLocalStorage(results[i].headers);
					}

					if (this._isMounted) {
						for (let i = 0; i < results.length; i++) {
							const company = new Employer(results[i].data);
							companies.push(company);
						}

						this.props.updateCurrentUserCompanies(companies);
						this.props.updateCurrentUserActiveCompany({ ...active_company });
						this.props.updateCurrentUserIsEmployerUser(companies.length > 0);

						this.setState(
							{
								sending: false,
								error_messages: {},
							},
							() => {
								this.props.updateCreateEmployerModal(false);

								const internal_uri = new InternalUri();
								this.props.history.push(
									internal_uri.formatted_dashboard_employer_profile_page_path(),
								);

								if (way === "green_channel") {
									this.props.updateWelcomeEmployerModal(
										true,
										i18n.language === "zh-TW"
											? welcome_banner_img_hp_zh
											: welcome_banner_img_hp_en,
										"we_have_placed_you_in_high_priority",
										"we_will_reach_you_via_the_information_you_have_provided_",
										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
										null,
									);
								} else if (form_data.plan_checked === "post_only") {
									this.props.updateWelcomeEmployerModal(
										true,
										i18n.language === "zh-TW"
											? welcome_banner_img_ft_zh
											: welcome_banner_img_ft_en,
										"you_are_all_set",
										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
										null,
										"we_are_pleased_to_provide_you__60_days_free_trial__",
									);
								} else {
									this.props.updateWelcomeEmployerModal(
										true,
										welcome_banner_img,
										"you_are_all_set",
										"you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
										null,
										null,
									);
								}
							},
						);
					}
				})
				.catch((error) => {
					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					if (this._isMounted) {
						if (typeof error.response === "undefined") {
							window.location.reload();
							this.props.updateConnectionOffModal(true);
						}

						this.setState((prev_state) => {
							if (
								error.response &&
								error.response.data &&
								error.response.data.status === "error"
							) {
								// 將 errors.contact 分別塞進 errors.contact.name & errors.contact.mobile
								if (
									error.response.data.errors &&
									error.response.data.errors.contact
								) {
									if (error.response.data.errors["contact.name"])
										error.response.data.errors["contact.name"].concat(
											error.response.data.errors.contact,
										);
									else
										error.response.data.errors["contact.name"] =
											error.response.data.errors.contact;

									if (error.response.data.errors["contact.mobile"])
										error.response.data.errors["contact.mobile"].concat(
											error.response.data.errors.contact,
										);
									else
										error.response.data.errors["contact.mobile"] =
											error.response.data.errors.contact;

									error.response.data.errors.contact = [];
								}

								// 將 errors.addressess 分別塞進 errors.addressess.handwriting_country & errors.addressess.handwriting_city
								if (
									error.response.data.errors &&
									error.response.data.errors.addresses
								) {
									if (
										error.response.data.errors["addresses.handwriting_country"]
									)
										error.response.data.errors[
											"addresses.handwriting_country"
										].concat(error.response.data.errors.addresses);
									else
										error.response.data.errors[
											"addresses.handwriting_country"
										] = error.response.data.errors.addresses;

									if (error.response.data.errors["addresses.handwriting_city"])
										error.response.data.errors[
											"addresses.handwriting_city"
										].concat(error.response.data.errors.addresses);
									else
										error.response.data.errors["addresses.handwriting_city"] =
											error.response.data.errors.addresses;

									error.response.data.errors.addresses = [];
								}
							}
							return {
								...prev_state,
								message_modal: {
									open: false,
								},
								error_messages:
									error.response &&
									error.response.data &&
									error.response.data.status === "error"
										? error.response.data.errors
										: {
												full_message:
													"There was an error. Please try again later.",
										  },
								steps: {
									...prev_state.steps,
									active: 1,
								},
								sending: false,
							};
						});

						if (this.props.site_state.create_employer_modal) {
							smoothly_scroll_to(
								document.querySelector(".modal-wrapper"),
								0,
								200,
							);
						} else {
							page_smothly_scroll_to(0, 300);
						}
					}
				});
		}
	};

	handleDownload = (e, url) => {
		e.preventDefault();
		downloadjs(url);
	};

	render() {
		const {
			preselected,
			steps,
			form_data,
			options,
			message_modal,
			error_messages,
			sending,
		} = this.state;
		const {
			handleInputChange,
			handleRadioChange,
			handleSelectChange,
			handleActiveStep,
			handleAgreementChange,
		} = this;
		const {
			cancelTheAction,
			createEmployerWithoutContract,
			submitForm,
			handleDownload,
		} = this;
		const { t } = this.props;
		const device_detector = new DeviceDetector();

		if (Object.keys(form_data).length === 0) return null;

		let has_errors = false;
		const white_list = ["full_message"];
		for (let key in error_messages) {
			if (white_list.indexOf(key) === -1 && error_messages[key].length)
				has_errors = true;
		}

		return (
			<form className="create-employer-form clearfix">
				<h2>{t("create_employer_account")}</h2>
				<div className="step-process-bar">
					{steps.active === 1 ? (
						<div className="step-process step-process-1 active">
							<p>1</p>
							<h6>{t("profile")}</h6>
						</div>
					) : (
						<div
							className="step-process step-process-1 selectable"
							onClick={(e) => handleActiveStep(e, 1)}>
							<p>1</p>
							<h6>{t("profile")}</h6>
						</div>
					)}
					{preselected ? null : (
						<div
							className={`line ${
								steps.active === 2 || steps.active === 3 ? "active" : ""
							}`}
						/>
					)}
					{preselected ? null : steps.active === 2 ? (
						<div className="step-process step-process-2 active">
							<p>2</p>
							<h6>{t("choose_plan")}</h6>
						</div>
					) : steps[2].visited ? (
						<div
							className="step-process step-process-2 selectable"
							onClick={(e) => handleActiveStep(e, 2)}>
							<p>2</p>
							<h6>{t("choose_plan")}</h6>
						</div>
					) : (
						<div className="step-process step-process-2">
							<p>2</p>
							<h6>{t("choose_plan")}</h6>
						</div>
					)}
					<div className={`line ${steps.active === 3 ? "active" : ""}`} />
					{steps.active === 3 ? (
						<div className="step-process step-process-3 active">
							<p>{preselected ? "2" : "3"}</p>
							<h6>{t("agreement")}</h6>
						</div>
					) : steps[3].visited ? (
						<div
							className="step-process step-process-3 selectable"
							onClick={(e) => handleActiveStep(e, 3)}>
							<p>{preselected ? "2" : "3"}</p>
							<h6>{t("agreement")}</h6>
						</div>
					) : (
						<div className="step-process step-process-3">
							<p>{preselected ? "2" : "3"}</p>
							<h6>{t("agreement")}</h6>
						</div>
					)}
				</div>
				{steps.active === 1 ? (
					<div className="step-1">
						<div className="container-fluid">
							<section className="section-create-account">
								<h4>{t("set_up_profile")}</h4>
								<div className="block basic-info-block">
									{error_messages.full_message ? (
										<h6 className="message error-message">
											{error_messages.full_message}
										</h6>
									) : (
										""
									)}
									<label htmlFor="employer-name">
										<h5>
											{t("employer_name")}
											<span className="asterisk">*</span>
										</h5>
									</label>
									<div className="form-row form-row-dire-col">
										<div className="form-col">
											<input
												className={`input ${
													error_messages.name && error_messages.name.length
														? "input-danger"
														: ""
												}`}
												type="text"
												value={form_data.name}
												onChange={(e) => handleInputChange(e, "name")}
												placeholder={t("your_employer_name")}
												id="employer-name"
											/>
										</div>
										<div className="form-col">
											{error_messages.name && error_messages.name.length ? (
												<h6 className="message error-message">
													{error_messages.name.join(", ")}
												</h6>
											) : (
												""
											)}
										</div>
									</div>

									<label htmlFor="contact-name">
										<h5>
											{t("contact_name")}
											<span className="asterisk">*</span>
										</h5>
									</label>
									<div className="form-row form-row-dire-col">
										<div className="form-col">
											<input
												className={`input ${
													error_messages["contact.name"] &&
													error_messages["contact.name"].length
														? "input-danger"
														: ""
												}`}
												type="text"
												value={form_data.contact_name}
												onChange={(e) => handleInputChange(e, "contact_name")}
												placeholder={t("full_name")}
												id="contact-name"
											/>
										</div>
										<div className="form-col">
											{error_messages["contact.name"] &&
											error_messages["contact.name"].length ? (
												<h6 className="message error-message">
													{error_messages["contact.name"].join(", ")}
												</h6>
											) : (
												""
											)}
										</div>
									</div>

									<label htmlFor="phone-number">
										<h5>
											{t("contact_phone_number")}
											<span className="asterisk">*</span>
										</h5>
									</label>
									<div className="form-row form-row-2 form-row-dire-col">
										<div className="form-col">
											<div className="form-row form-row-2 form-row-phone-number">
												<div className="form-col">
													<Select
														className="drop-down drop-down-larger drop-down-grey"
														clearable={false}
														value={form_data.phone_codes_selected_value}
														onChange={(value) =>
															handleSelectChange(
																"phone_codes_selected_value",
																value,
															)
														}
														options={options.phone_codes}
													/>
												</div>
												<div className="form-col">
													<input
														className={`input ${
															error_messages["contact.mobile"] &&
															error_messages["contact.mobile"].length
																? "input-danger"
																: ""
														}`}
														type="text"
														value={form_data.contact_phone_number}
														onChange={(e) =>
															handleInputChange(e, "contact_phone_number")
														}
														placeholder={t("your_phone_number")}
														id="phone-number"
													/>
												</div>
											</div>
										</div>
										{error_messages["contact.mobile"] &&
										error_messages["contact.mobile"].length ? (
											<h6 className="message error-message">
												{error_messages["contact.mobile"].join(", ")}
											</h6>
										) : (
											""
										)}
									</div>

									<label htmlFor="location">
										<h5>
											{t("location")}
											<span className="asterisk">*</span>
										</h5>
									</label>
									<div className="form-row form-row-dire-col form-row-location">
										<div className="form-col">
											{error_messages["addresses.handwriting_country"] &&
											error_messages["addresses.handwriting_country"].length ? (
												<h6 className="message error-message">
													{error_messages["addresses.handwriting_country"].join(
														", ",
													)}
												</h6>
											) : (
												""
											)}
											<div className="select-wrapper">
												<Select
													className={`drop-down drop-down-larger drop-down-grey ${
														error_messages["addresses.handwriting_country"] &&
														error_messages["addresses.handwriting_country"]
															.length
															? "input-danger"
															: ""
													}`}
													clearable={false}
													value={form_data.countries_selected_value}
													onChange={(value) =>
														handleSelectChange(
															"countries_selected_value",
															value,
														)
													}
													options={options.countries}
												/>
											</div>
										</div>
										<div className="form-col">
											{error_messages["addresses.handwriting_city"] &&
											error_messages["addresses.handwriting_city"].length ? (
												<h6 className="message error-message">
													{error_messages["addresses.handwriting_city"].join(
														", ",
													)}
												</h6>
											) : (
												""
											)}
											<input
												className={`input ${
													error_messages["addresses.handwriting_city"] &&
													error_messages["addresses.handwriting_city"].length
														? "input-danger"
														: ""
												}`}
												type="text"
												value={form_data.city}
												onChange={(e) => handleInputChange(e, "city")}
												placeholder={t("city")}
											/>
										</div>
									</div>

									<label className="lines" htmlFor="company-sub-domain">
										<h5>
											{t("your_domain_on_meet_jobs")}
											<span className="asterisk">*</span>
										</h5>
										<h6>
											{t("_only_letters_numbers_and_hyphens_are_permitted_")}
										</h6>
									</label>
									<div className="form-row form-row-2 form-row-dire-col">
										<div className="form-col">
											<div className="form-row form-row-sub-domain">
												<p>https://meet.jobs/</p>
												<input
													className={`input ${
														error_messages.slug && error_messages.slug.length
															? "input-danger"
															: ""
													}`}
													type="text"
													value={form_data.slug}
													onChange={(e) => handleInputChange(e, "slug")}
													placeholder={t("_employer_name")}
													id="company-sub-domain"
												/>
											</div>
										</div>
										{error_messages.slug && error_messages.slug.length ? (
											<h6 className="message error-message">
												{error_messages.slug.join(", ")}
											</h6>
										) : (
											""
										)}
									</div>

									<label htmlFor="referrer_information">
										<h5>{t("how_did_you_hear_about_us_")}</h5>
									</label>
									<div className="form-row form-row-dire-col">
										<Textarea
											className="textarea"
											minRows={3}
											maxRows={3}
											value={form_data.referrer_information}
											placeholder={t("a_person_s_name___contact")}
											onChange={(e) =>
												handleInputChange(e, `referrer_information`)
											}
											id="referrer_information"
										/>
									</div>
								</div>
							</section>
							<section className="section-next">
								{sending || has_errors ? (
									<button
										onClick={(e) => e.preventDefault()}
										className="btn btn-larger btn-float btn-fill disabled">
										<h5>{t("next")}</h5>
									</button>
								) : (
									<button
										className="btn btn-larger btn-float btn-fill"
										onClick={(e) => handleActiveStep(e, preselected ? 3 : 2)}>
										<h5>{t("next")}</h5>
									</button>
								)}
							</section>
						</div>
					</div>
				) : (
					""
				)}
				{steps.active === 2 ? (
					<div className="step-2">
						<div className="container plans-container">
							<section className="section-choose-plan">
								<h4>{t("choose_plan")}</h4>
								<div className="desktop-wrapper">
									<div className="plans-wrapper">
										<div className="plans">
											<Slider {...this.slider_settings}>
												<div className="plan-wrapper">
													<input
														name="plan"
														checked={
															form_data.plan_checked === "social_referral"
														}
														type="radio"
														className="radio-input"
														readOnly
													/>
													<label
														htmlFor="social_referral"
														className="radio-plan"
														onClick={(e) =>
															handleRadioChange(
																e,
																"plan_checked",
																"social_referral",
															)
														}>
														<div className="block plan plan-hire">
															<h4>{t("plan_hire")}</h4>
															<div
																className="image-wrapper"
																style={{
																	backgroundImage: `url(${plan_hire})`,
																}}>
																{/*<span*/}
																{/*    className="recommend">{t('recommend').toUpperCase()}</span>*/}
															</div>
															<div className="prices">
																<h3>{t("_16__annual_salary")}</h3>
																<h5>{t("per_successful_hire")}</h5>
															</div>
															<hr />
															<p>
																{t(
																	"general:commnuity_referral_quick_and_precise",
																)}
															</p>
															<p>
																{t(
																	"general:emplouyer_brandind_and_dynamic_promotion",
																)}
															</p>
															<p>
																{t(
																	"general:integrated_marketing_and_speed_interview_event",
																)}
															</p>

															<div className="btn btn-larger btn-flat btn-hollow btn-call-to-action">
																{form_data.plan_checked ===
																"social_referral" ? (
																	<h5 className="content">
																		{t("general:selected")}
																	</h5>
																) : (
																	<h5 className="content">
																		{t("general:select_plan")}
																	</h5>
																)}
															</div>
														</div>
													</label>
												</div>
												<div className="plan-wrapper">
													<input
														name="plan"
														checked={
															form_data.plan_checked === "custom_referral"
														}
														type="radio"
														className="radio-input"
														readOnly
													/>
													<label
														htmlFor="custom_referral"
														className="radio-plan"
														onClick={(e) =>
															handleRadioChange(
																e,
																"plan_checked",
																"custom_referral",
															)
														}>
														<div className="block plan plan-custom">
															<h4>{t("plan_hire_")}</h4>
															<div
																className="image-wrapper"
																style={{
																	backgroundImage: `url(${plan_hire_plus})`,
																}}
															/>
															<div className="prices clearfix">
																<h3>{t("_20__annual_salary")}</h3>
																<h5>{t("per_successful_hire")}</h5>
															</div>
															<hr />
															{/*<h6>{t('general:everything_in_hire_plan')}</h6>*/}
															{/*<p>{t('general:recruitment_consulting_service')}</p>*/}
															<p>{t("everything_in_social_plan")}</p>
															<p>{t("professional_hunter_search")}</p>
															<p>{t("general:double_screening_ai")}</p>
															<p>
																{t(
																	"general:professional_slill_language_adaptive_tests",
																)}
															</p>
															<p>{t("general:customized_service_contract")}</p>

															<div className="btn btn-larger btn-flat btn-hollow btn-call-to-action">
																{form_data.plan_checked ===
																"custom_referral" ? (
																	<h5 className="content">
																		{t("general:selected")}
																	</h5>
																) : (
																	<h5 className="content">
																		{t("general:select_plan")}
																	</h5>
																)}
															</div>
														</div>
													</label>
												</div>
											</Slider>
										</div>
									</div>
								</div>
							</section>
							<section className="section-go-create">
								<div className="buttons-wrapper">
									<button
										className="btn btn-larger btn-float btn-hollow btn-back"
										onClick={(e) => handleActiveStep(e, 1)}>
										<h5>{t("back")}</h5>
									</button>
									<button
										className="btn btn-larger btn-float btn-fill"
										onClick={(e) => handleActiveStep(e, 3)}>
										<h5>{t("next")}</h5>
									</button>
								</div>
							</section>
						</div>
					</div>
				) : (
					""
				)}
				{steps.active === 3 ? (
					<div className="step-3">
						<div className="container-fluid">
							{form_data.plan_checked === "social_referral" ||
							form_data.plan_checked === "custom_referral" ? (
								<div>
									<h4 className="text-center">
										{t(`${form_data.plan_checked}_name`)}
									</h4>
									<section className="section-three-basic-rules">
										<div className="desktop-wrapper">
											<h4>{t("three_basic_rules")}</h4>
											<ThreeBasicRules type={form_data.plan_checked} />
										</div>
									</section>
								</div>
							) : (
								""
							)}
							<section className="section-contract">
								<div className="desktop-wrapper">
									<h4>
										{t("employer_user_agreement")}
										{device_detector.is_mobile_or_tablet() ? (
											<a
												className="btn btn-larger btn-float btn-hollow btn-with-icon"
												href={`/public_assets/contracts/${t(
													"contract_file_name",
												)}`}
												target="_blank"
												rel="noopener noreferrer">
												<h5>
													<img src={icon_download} alt="" />
													{t("general:download")}
												</h5>
											</a>
										) : (
											<button
												className="btn btn-larger btn-float btn-hollow btn-with-icon"
												onClick={(e) =>
													handleDownload(
														e,
														`/public_assets/contracts/${t(
															"contract_file_name",
														)}`,
													)
												}>
												<h5>
													<img src={icon_download} alt="" />
													{t("general:download")}
												</h5>
											</button>
										)}
									</h4>
									<Textarea
										className="textarea"
										minRows={12}
										maxRows={12}
										value={t(
											"frontend_employer_agreement_online_text:text_version",
										)}
										readOnly
										id="contract_content"
									/>
									<input
										type="checkbox"
										className="checkbox-input"
										checked={form_data.agreement_checked}
										readOnly
									/>
									<label
										className="checkbox"
										onClick={(e) => handleAgreementChange(e)}>
										{form_data.plan_checked === "social_referral" ||
										form_data.plan_checked === "custom_referral" ? (
											<p>
												{t(
													"i_accept_meet_jobs_3_basic_rules_and_employer_user_agreement",
												)}
											</p>
										) : (
											<p>{t("i_accept_meet_jobs_employer_user_agreement")}</p>
										)}
									</label>
								</div>
							</section>
							<section className="section-go-create">
								<div className="desktop-wrapper">
									<div className="buttons-wrapper">
										<button
											className="btn btn-larger btn-float btn-hollow btn-back"
											onClick={(e) => handleActiveStep(e, preselected ? 1 : 2)}>
											<h5>{t("back")}</h5>
										</button>
										{form_data.agreement_checked && !sending ? (
											<button
												className="btn btn-larger btn-float btn-fill"
												onClick={(e) => submitForm(e)}>
												<h5>{t("create_employer")}</h5>
											</button>
										) : (
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-larger btn-float btn-fill disabled">
												<h5>{t("create_employer")}</h5>
											</button>
										)}
									</div>
									{!form_data.agreement_checked &&
									(form_data.plan_checked === "social_referral" ||
										form_data.plan_checked === "custom_referral") ? (
										<div className="buttons-wrapper">
											<button
												className="link use-first-link"
												onClick={(e) => createEmployerWithoutContract(e)}>
												<h5>{t("use_first__contract_later__")}</h5>
											</button>
										</div>
									) : (
										""
									)}
								</div>
							</section>
						</div>
					</div>
				) : (
					""
				)}

				<CSSTransitionGroup
					component="div"
					transitionName="modal-wrapper-with-modal-slide"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
					{message_modal.open ? (
						<div className="modal-wrapper double-confirm-modal-wrapper">
							<div className="modal-inner-wrapper">
								<div className="modal-bg" />
								<div className="modal double-confirm-modal with-pop-up-icon">
									<div
										className="image-wrapper"
										style={{ backgroundImage: `url(${modal_pop_up_assure}` }}
									/>
									<div className="content">
										<h4 className="modal-title">
											{t(
												"are_you_sure_to_create_your_employer_account_and_accept_3_basic_rules_",
											)}
										</h4>
										{error_messages.full_message ? (
											<h6 className="message error-message">
												{error_messages.full_message}
											</h6>
										) : (
											""
										)}
										<div className="buttons-wrapper">
											<button
												className="btn btn-larger btn-flat btn-ghost"
												onClick={(e) => cancelTheAction(e)}>
												<h5>{t("general:cancel")}</h5>
											</button>
											<button
												className="btn btn-larger btn-flat btn-fill"
												onClick={(e) => submitForm(e, "green_channel")}>
												<h5>{t("general:accept")}</h5>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</CSSTransitionGroup>
			</form>
		);
	}
}

export default withNamespaces([
	"general",
	"frontend_employer_agreement_online_text",
])(CreateEmployerForm);
