import i18n from './../../i18n';
import {formatted_locale} from "../../assets/js/functions";

let instances = {};

class InternalUri {
    constructor(language=i18n.language) {
        this.language = language;

        if(!instances.hasOwnProperty(language)) instances[language] = this;
        return instances[language];
    }

    formatted_path(raw_path, suggest_language=this.language) {
        // query language to sub folder language
        // no language -> add language from request
        let path_array = raw_path.split('/');
        let subfolder_locale = path_array[1] ? path_array[1] : '';
        if(
            subfolder_locale.split('-')[0] === 'zh' ||
            subfolder_locale.split('-')[0] === 'en'
        ) {
            path_array = path_array.slice(2, path_array.length);
            path_array = [formatted_locale(subfolder_locale), ...path_array];
        } else {
            path_array = path_array.slice(1, path_array.length);
            path_array = [formatted_locale(suggest_language), ...path_array];
        }
        return '/' + path_array
            .map(subfolder => subfolder)
            .join('/');
    }

    formatted_path_with_new_language(prev_pathname, new_language=this.language) {
        let pathname_array = prev_pathname.split('/');

        if(pathname_array.length <= 2) return `/${new_language}`;
        let subfolder_locale = pathname_array[1] ? pathname_array[1] : '';
        if(
            subfolder_locale.split('-')[0] === 'zh' ||
            subfolder_locale.split('-')[0] === 'en'
        ) {
            pathname_array = pathname_array.slice(2, pathname_array.length);
        } else {
            pathname_array = pathname_array.slice(1, pathname_array.length);
        }
        pathname_array = [formatted_locale(new_language), ...pathname_array];
        return '/' + pathname_array
            .map(subfolder => subfolder)
            .join('/');
    }

    // General

    formatted_external_job_redirect_page_path() {
        return `/${this.language}/external-job-redirect`;
    }

    // Frontend

    formatted_frontend_index_page_path() {
        return `/${this.language}`;
    }

    formatted_frontend_jobs_page_path(query_string='') {
        // ?page=1&order=match
        return `/${this.language}/jobs${query_string}`;
    }

    formatted_frontend_employer_landing_page_path() {
        return `/${this.language}/employer-landing`;
    }

    formatted_frontend_referrer_landing_page_path() {
        return `/${this.language}/referrer-landing`;
    }

    formatted_frontend_employer_page_path(employer_id, employer_slug) {
        return `/${this.language}/employers/${employer_id}-${employer_slug}`;
    }

    formatted_frontend_job_page_path(job_id, job_slug) {
        return `/${this.language}/jobs/${job_id}-${job_slug}`;
    }

    formatted_frontend_job_invitation_page_path(invitation_id) {
        return `/${this.language}/job-invitations/${invitation_id}`;
    }

    formatted_frontend_refer_page_path(referral_type, referral_type_id) {
        return `/${this.language}/refer/${referral_type}/${referral_type_id}`;
    }

    formatted_frontend_about_us_page_path() {
        return `/${this.language}/about-us`;
    }

    formatted_frontend_confirm_done_page_path() {
        return `/${this.language}/confirm-done`;
    }

    formatted_frontend_confirm_page_path() {
        return `/${this.language}/confirm`;
    }

    formatted_frontend_create_employer_page_path() {
        return `/${this.language}/create-employer`;
    }

    formatted_frontend_forgot_password_page_path() {
        return `/${this.language}/forgot-password`;
    }

    formatted_frontend_reset_password_page_path() {
        return `/${this.language}/reset-password`;
    }

    formatted_frontend_log_in_page_path() {
        return `/${this.language}/log-in`;
    }

    formatted_frontend_sign_up_page_path() {
        return `/${this.language}/sign-up`;
    }

    formatted_frontend_password_changed_page_path() {
        return `/${this.language}/password-changed`;
    }

    formatted_frontend_privacy_and_terms_page_path() {
        return `/${this.language}/privacy-and-terms`;
    }

    formatted_frontend_error_500_page_path() {
        return `/${this.language}/500`;
    }

    formatted_frontend_error_404_page_path() {
        return `/${this.language}/404`;
    }

    formatted_frontend_redirect_from_facebook_page_path() {
        return `/${this.language}/redirect-from-facebook`;
    }

    formatted_frontend_redirect_from_sign_in_email_page_path() {
        return `/${this.language}/redirect-from-sign-in-email`;
    }

    formatted_frontend_redirect_from_forgot_password_email_page_path() {
        return `/${this.language}/redirect-from-forgot-password-email`;
    }

    // Dashboard

    formatted_dashboard_index_page_path() {
        return `/${this.language}/dashboard`;
    }

    formatted_dashboard_jobs_page_path() {
        return `/${this.language}/dashboard/jobs`;
    }

    formatted_dashboard_job_page_path(job_id, type) {
        return `/${this.language}/dashboard/jobs/job/${job_id}/${type}`;
    }

    formatted_dashboard_create_job_page_path() {
        return `/${this.language}/dashboard/jobs/create-job`
    }

    formatted_dashboard_applications_page_path() {
        return `/${this.language}/dashboard/applications`;
    }

    formatted_dashboard_employer_profile_page_path() {
        return `/${this.language}/dashboard/employer-profile`;
    }

    formatted_dashboard_invoices_page_path() {
        return `/${this.language}/dashboard/invoices`;
    }

    formatted_dashboard_payment_setting_page_path() {
        return `/${this.language}/dashboard/payment-setting`;
    }

    formatted_dashboard_member_management_page_path() {
        return `/${this.language}/dashboard/member-management`;
    }

    formatted_dashboard_upgrade_plans_path() {
        return `/${this.language}/dashboard/upgrade-plans`;
    }

    // Userpanel

    formatted_userpanel_index_page_path() {
        return `/${this.language}/userpanel`;
    }

    formatted_userpanel_my_applications_page_path() {
        return `/${this.language}/userpanel/my-applications`;
    }

    formatted_userpanel_saved_jobs_page_path() {
        return `/${this.language}/userpanel/saved-jobs`;
    }

    formatted_userpanel_my_referrals_page_path() {
        return `/${this.language}/userpanel/my-referrals`;
    }

    formatted_userpanel_my_resume_page_path() {
        return `/${this.language}/userpanel/my-resume`;
    }

    // Talents

    formatted_talents_talent_page_path(talent_id) {
        return `/${this.language}/talents/${talent_id}`
    }
}

export default InternalUri;