export default {
    posted_on: "張貼於",
    job_information: "工作機會資訊",
    job_title: "工作機會名稱",
    job_type: "工作機會類型",
    remote: "遠端",
    not_allowed: "不允許",
    salary: "薪資",
    at_least: "下限",
    up_to: "上限",
    currency: "貨幣",
    paid_by_per: "薪資計算週期",
    location: "地點",
    _select_up_to_3_: "(最多選擇 3 個)",
    noSuitableJobFunctionContactInfo: "如果您找不到適合的職能標籤，請{{contact_us}}。",
    contact_us: "聯繫我們",
    skills: "技能要求",
    _separate_by_enter_to_create_multiple_items_: "(請以 Enter 鍵隔開，且建議以英文為主)",
    job_description: "工作機會描述",
    people_to_notify: "Email 通知信",
    members: "企業成員",
    _select_members_for_receiving_candidate_s_information_you_can_manage_members_at_member_management_section_: "(選擇可以收到應徵資訊的成員)",
    to_save_your_changes__click_save_: "要儲存以下輸入的變更，請按「儲存」。",
    to_post_your_job__click_post_: "要張貼此工作機會草稿，請按「張貼」。",
    update_your_job_post_to_extend_for_60_more_days_: "更新工作機會來延長60天的刊登。",
    more_detail_will_help_candidates_understand_your_need_for_this_position_: "完整的資訊將會幫助求職者了解您的徵才需求。",
    post: "立即張貼",
    posting: "張貼中",
    we_will_create_a_new_job_post_with_the_same_content_and_keep_the_old_one_for_the_record_: "我們會幫您建立為一份相同內容的新工作機會，並留存舊的工作機會作為紀錄。",
    apply: "確認",
    select_job_functions: "選擇職能"
};
